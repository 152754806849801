import React, { useEffect } from "react";
import { Drawer, Tag } from "@stonelog/stonelog-design-system";
import { frequencyHistoricSelector, getFrequencyHistoricByAngel } from "../../../../../../../features/senninha/GestaoEquipe/frequencyHistoricSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "@utils/formatter";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";
import Au from "@assets/icons/Au.svg";
import SelectDatetime from "@components/SelectDatetime";

import * as S from "./styles";


const FrequencyDrawer = ({
    open,
    setOpen,
    rowClickedInfos,
    setSelectedMonth,
    selectedMonth
}) => {

    const dispatch = useDispatch()

    const {
        frequencyHistoricAngelData,
        frequencyHistoricAngelStatus
    } = useSelector(frequencyHistoricSelector);

    useEffect(() => {
        if (rowClickedInfos.email !== undefined && open === true) {
            dispatch(getFrequencyHistoricByAngel({
                email: rowClickedInfos.email,
                mes: selectedMonth.value
            }))
        }
    }, [selectedMonth, open]);

    return (
        <Drawer.Root
            open={open}
            title={<Drawer.Header title={capitalizeAllFirstLetters(rowClickedInfos?.nome)} date={new Date()} />}
            onClose={() => setOpen(false)}
        >
            <Drawer.HeaderActions style={{ justifyContent: 'space-between' }}>
                <SelectDatetime
                    titled={true}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                />
            </Drawer.HeaderActions>
            {frequencyHistoricAngelStatus === 'pending' ? (
                <Drawer.Loading />
            ) : frequencyHistoricAngelData?.length !== 0 ? (
                <Drawer.Body>
                    {frequencyHistoricAngelData.map((frequency, idx) => {
                        return (
                            <S.FrequencyDrawerRow key={idx}>
                                <div>
                                    <span>
                                        {frequency?.justificativa ? (
                                            `${formatDateTime(frequency?.data_falta)} - ${frequency?.justificativa}`
                                        ) : (
                                            formatDateTime(frequency?.data_falta)
                                        )}
                                    </span>
                                    {frequency?.justificado_por && (
                                        <p>
                                            {`Justificado por ${frequency?.justificado_por}, em ${formatDateTime(frequency?.data_justificativa)} às ${frequency?.hora_justificativa}`}
                                        </p>
                                    )}
                                    {frequency?.data_aprovacao && (
                                        <p>
                                            {`${frequency.status.mensagem} por ${frequency?.aprovado_por}, em ${formatDateTime(frequency?.data_aprovacao)}`}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <Tag type={frequency.status.tipo} text={frequency?.status.mensagem} />
                                </div>
                            </S.FrequencyDrawerRow>
                        )
                    })}

                </Drawer.Body>
            ) : (
                <Drawer.Response
                    element={<S.Au src={Au} />}
                    message="Aí sim! O colaborador não tem histórico de faltas."
                />
            )}
        </Drawer.Root>
    );
};

export default FrequencyDrawer;
