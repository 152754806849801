import React from "react";
import { PageLayout } from "../../../../../../Layout/PageLayout";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../../../../../components/Breadcumbs";
import PageHeader from "@components/PageHeader";
import { Button } from "@stonelog/stonelog-design-system";

import * as S from "./styles";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";


const ControleDeRoteirizacao = () => {

    return (
        <PageLayout
            titlePage={" |  Senninha"}
            showHamburguerIcon={true}
        >
            <S.AllPageTeamController>
                <Breadcrumbs />
                <S.HeaderPageTeamController>
                    <PageHeader title="Controle de Roteirização" />
                    <Button
                        size="small"
                    >
                        <GingaIcon name="round-add" color="rgba(48, 55, 66, 1)" size={16}/>
                        Novo colaborador
                    </Button>
                </S.HeaderPageTeamController>
            </S.AllPageTeamController>
        </PageLayout>
    );
};

export default ControleDeRoteirizacao;
