import styled from "styled-components";


export const SelectHeaderString = styled.div`
  display: flex;
  flex-direction: column;

  span{
      overflow: hidden;
      color: var(--Content-Neutral-High, #303742);
      text-overflow: ellipsis;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%; /* 21.75px */
      letter-spacing: -0.15px;
  }

  p{
      color: var(--Content-Neutral, #767F8D);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%; /* 18.85px */
      letter-spacing: -0.13px;
      text-wrap: nowrap;
  }
`;

export const SelectDatetimeDiv = styled.div`

 .ant-select .ant-select-selector, .ant-select .ant-select-selector:hover {
    background-color: rgba(118, 127, 141, 0.1) !important;
    border: 0 !important;
    display: flex;
    gap: 4px;
    align-items: center;
    color: rgba(48, 55, 66, 1) !important;
  }
  .ant-select-prefix{
    display: flex;
  }
`;
