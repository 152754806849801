import React from "react";
import Breadcrumbs from "../../../../../../components/Breadcumbs";
import PageHeader from "@components/PageHeader";
import UnproductiveTable from "./UnproductiveTable";
import FrequencyHistory from "./FrequencyHistory";
import { PageLayout } from "../../../../../../Layout/PageLayout";
import { Alert } from "@stonelog/stonelog-design-system";

import * as S from "./styles";


const UnproductivityJustification = () => {

    return (
        <PageLayout
            titlePage={" |  Senninha"}
            showHamburguerIcon={true}
        >
            <S.AllPageTeamUnproductive>
                <Breadcrumbs />
                <PageHeader
                    title="Justificativa de Improdutividade"
                />
                <S.ContentPageTeamUnproductive>
                    <Alert description="Justificativas devem ser feitas apenas por líderes e precisam de aprovação de um Regional." type="info" />
                    
                    <UnproductiveTable />

                    <FrequencyHistory />

                </S.ContentPageTeamUnproductive>
            </S.AllPageTeamUnproductive>
        </PageLayout>
    );
};

export default UnproductivityJustification;
