import React, { useState, useRef, useEffect } from "react";
import { Tag } from "antd";
import { toCapitalizeFirstLetterOfEachWord } from "../../consumingApi/services/helper";

import * as S from "./styles";


export const TagCustom = ({
  setTags,
  tags,
  closable,
  closeIcon,
  onClick,
  clickable,
  onClose,
  relocateCustomTag, // relocateCustomTag -> funcionalidade de deixar a tag mais redonda (design)
  activeTags,
  customIsSelected
}) => {
  const [checked, setChecked] = useState(true);
  const ScrollToBottomRef = useRef(null);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  useEffect(() => {
    if (ScrollToBottomRef?.current) {
      ScrollToBottomRef.current.scrollTo({ top: 100000 });
    }
  }, [ScrollToBottomRef, tags]);

  return (
    <>
      <S.CustomTag
        relocateCustomTag={relocateCustomTag}
        clickable={clickable}
        ref={ScrollToBottomRef}
      >
        {tags.map((tag, idx) => {
          let isSelected = undefined
          if (customIsSelected !== undefined) {
            isSelected = activeTags.some(item => item.value === tag.value)
          } else {
            isSelected = activeTags
            ? activeTags.some(item => JSON.stringify(item) === JSON.stringify(tag))
            : false
          }

          return (
            <Tag
              onClose={(e) => {
                e.preventDefault();
                onClose ? onClose(tag) : handleClose(tag);
                setChecked(false);
              }}
              closable={closable}
              closeIcon={closeIcon}
              onClick={onClick ? () => onClick(tag) : undefined}
              clickable={clickable}
              checked={checked}
              key={idx}
              style={{
                backgroundColor: isSelected && clickable ? '#00A8681A' : '',
                border: isSelected && clickable ? '1.5px solid #00A868' : '',
                color: isSelected && clickable ? '#008E5A' : ''
              }}
            >
              {toCapitalizeFirstLetterOfEachWord(tag?.value)}
            </Tag>
          );
        })}
      </S.CustomTag>
    </>
  );
};
