import React, { useState } from 'react';
import { Button, Dialog, Drawer, Select, Tag } from '@stonelog/stonelog-design-system';
import RelocateRoutes from './Tabs/RoutePlanning/RelocateRoutes';
import warning from "@assets/icons/Warning.svg";
import RoutePlanning from './Tabs/RoutePlanning';
import { useSelector } from 'react-redux';
import PreDelivery from './Tabs/PreDelivery/PreDelivery';
import { routesManagementSelector } from '../../../../../../../features/senninha/routesManagementSlice';

import * as S from './styles';


const RoutesDrawer = ({
    onClose,
    open,
    showNotification
}) => {
    const [activeKeyTab, setActiveKeyTab] = useState('1')
    const [openCloseModal, setOpenCloseModal] = useState(false);
    const [onAuxCloseModal, setOnAuxCloseModal] = useState(null);

    const {
        selectedPreDeliveryPins,
    } = useSelector(routesManagementSelector);

    const handleDrawerOnClose = (doOnClose) => {
        const hasPredelivPins = selectedPreDeliveryPins?.length;

        if (typeof doOnClose === 'function' && hasPredelivPins) {
            setOnAuxCloseModal(() => doOnClose);
            setOpenCloseModal(true)
        } else if (hasPredelivPins) setOpenCloseModal(true)
        else onClose()   
    }
    
    return (
        <>
            <Dialog.Root
                open={openCloseModal}
                onCancel={() => setOpenCloseModal(false)}
            >
                <Dialog.Header title="Atenção" />

                <Dialog.ContentHeader
                ilustration={<img src={warning} />}
                description="Ao cancelar, você perderá todas as informações inseridas. Tem certeza de que deseja continuar?"
                />

                <Dialog.Footer>
                <Button onClick={() => setOpenCloseModal(false)}>Voltar</Button>
                <Button
                    type="negative"
                    onClick={() => {
                        if (!!onAuxCloseModal) {
                            onAuxCloseModal()
                            setOpenCloseModal(false)
                        }
                        else onClose()
                    }}
                >
                    Confirmar cancelamento
                </Button>
                </Dialog.Footer>
            </Dialog.Root>

            <Drawer.Root
                placement='right'
                open={open}
                onClose={handleDrawerOnClose}
                mask={false}
                title={
                    <Drawer.Header
                        title='Gestão de Rotas'
                        subTitle='Aqui você pode realocar, desalocar, trocar rotas e solicitar deliveries.'
                    />
                }
            >
                <Drawer.Tabs
                    activeKey={activeKeyTab}
                    onChange={setActiveKeyTab}
                    items={[
                        {
                        key: '1',
                        label: 'Roteirização',
                        children: (
                            <RoutePlanning
                                onClose={handleDrawerOnClose}
                                showNotification={showNotification}
                                activeKeyTab={activeKeyTab}
                                setActiveKeyTab={setActiveKeyTab}
                            />
                        ),
                        },
                        {
                        key: '2',
                        label: 
                            <S.PreDeliveryLabel>
                                lista pré-delivery 
                                {selectedPreDeliveryPins?.length ?
                                    <S.PreDeliveryBadge>{` ${selectedPreDeliveryPins.length}`}</S.PreDeliveryBadge>
                                    : ''
                                }
                            </S.PreDeliveryLabel>,
                        children: (
                            <PreDelivery 
                                onClose={handleDrawerOnClose}
                                onCloseWithoutModal={onClose}
                                setActiveKeyTab={setActiveKeyTab}
                            />
                        ),
                        },
                    ]}
                />
            </Drawer.Root>
        </>
    )
};

export default RoutesDrawer;
