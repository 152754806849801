import React, { useEffect, useMemo } from 'react';
import { FormInput, FormSelect, FormTextArea, Drawer, Button, Loading, Tag } from '@stonelog/stonelog-design-system';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import { usePreDeliverySimulationContext } from '../../../../../../../../../../hooks/usePreDeliverySimulationContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventSchema } from './schemas/schemas';
import { useSelector, useDispatch } from 'react-redux';
import { optionsSelector } from '../../../../../../../../../../features/optionsSlice';
import { getValidateSerial, preDeliveryFormSelector, resetValidationSerialStates } from '../../../../../../../../../../features/senninha/preDeliveryFormSlice';

import * as S from '../styles';


const EventForm = ({
    os,
    infoOs,
    obsDefaultValue,
    outputSerial,
    inputSerial,
    posRecommendation,
    solution,
    defect,
    isInMultiGroupForm,
    handleSaveClient
}) => {
    const { 
        serialValidationData,
        statusValidateSerial,
        errorValidateSerial
    } = useSelector(preDeliveryFormSelector)

    const {
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
    } = usePreDeliverySimulationContext()

    const dispatch = useDispatch()

    const {
        defects,
        solutions
    } = useSelector(optionsSelector);

    const initialFormData = useMemo(() => formsSimulationIdData[os]?.formData ?? {
        osInfo: infoOs,
        inputSerial,
        outputSerial,
        defects: defect,
        solutions: solution,
        notes: obsDefaultValue
    }, [formsSimulationIdData, os, infoOs, inputSerial, outputSerial, defect, solution, obsDefaultValue]);

    const {
        control,
        formState: { isValid, isValidating, errors },
        getValues,
        trigger,
        watch
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(eventSchema(isInMultiGroupForm)),
        defaultValues: initialFormData
    });

    const serial = watch('inputSerial');
    const isSuccess = serialValidationData?.tipo === 'success' && statusValidateSerial === 'fulfilled' || !!inputSerial;
    const isError = serialValidationData?.tipo === 'error' || errorValidateSerial;
    const isLoading = statusValidateSerial === 'pending';

    const handleValidateSerial = () => {
        if (!isLoading) {
            if (errorValidateSerial) {
                dispatch(resetValidationSerialStates());
            }
            if (serial) {
                dispatch(getValidateSerial({
                    terminal_entrada: serial,
                    os
                }));
            }
        }
    }

    const handleSave = () => {
        setFormsSimulationIdData((p) => ({
            ...p,
            [os]: {
                ...p[os],
                isSubmitted: true,
                formData: getValues(),
            }
        }))
        if (!isInMultiGroupForm) handleSaveClient()
    }

    const createOptions = (arrayStr) => 
        arrayStr.map((item) => ({ value: item, label: item }))

    useEffect(() => {
        dispatch(resetValidationSerialStates());
        trigger()

        return () => {
            if (isInMultiGroupForm) handleSave();
            dispatch(resetValidationSerialStates());
        };
    }, [])

    useEffect(() => {
        if (!Object.keys(errors).length)
            setFormsSimulationIdValidations({
                ...formsSimulationIdValitations,
                [os]: {
                    ...formsSimulationIdValitations[os],
                    isValid: isValid && isSuccess,
                }
            })
    }, [isValidating, errors, isSuccess])

    useEffect(() => {
        if (!serial && isError){
            dispatch(resetValidationSerialStates());
        }
    }, [serial, isError])

  return (
    <S.FormArea>
        <S.ClientFormBlock>
            <FormTextArea
                control={control}
                name="osInfo"
                autoSize
                label="Info da OS"
                disabled
            />
            <FormInput
                control={control}
                name="outputSerial"
                label="Serial de saída"
                placeholder="Bipe ou digite aqui"
                disabled
                size="small"
            />
            <FormInput
                control={control}
                name="inputSerial"
                label="Serial de entrada"
                placeholder="Bipe ou digite aqui"
                suffixIcon={isLoading? 
                    <Loading size={20} borderWidth={2}/> 
                    : <GingaIcon color="#767F8D" name="code-bar-scanner" size={24}/>
                }
                disabled={isLoading}
                supportText={
                    isError ? 
                    serialValidationData?.mensagem || 'error' 
                    : <Tag type='info' text={`Recomendado: ${posRecommendation}`}/>
                }
                size="small"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') handleValidateSerial()
                }}
                {...(isError ? { status: 'error' } : {})}
            />
            <FormSelect
                control={control}
                name="defects"
                label="Defeito"
                options={createOptions(defects)}
                placeholder="Selecione uma opção"
                size="small"
            />
            <FormSelect
                control={control}
                name="solutions"
                label="Solução"
                options={createOptions(solutions)}
                placeholder="Selecione uma opção"
                size="small"
            />
            {!isInMultiGroupForm && (
                <FormTextArea
                    control={control}
                    name="notes"
                    label="Observações"
                    rows={3}
                    style={{ resize: 'none' }}
                />
            )}
        </S.ClientFormBlock>

        {!isInMultiGroupForm &&
            <Drawer.Footer
                style={{ margin: '0 -24px' }}
            >
                <Button
                    type='primary'
                    width='100%'
                    height='52px'
                    size='large'
                    onClick={handleSave}
                    disabled={!isValid || !isSuccess}
                    className="ginga-lib-button"
                >
                    Salvar
                </Button>
            </Drawer.Footer>
        }
    </S.FormArea>
  )
};

export default EventForm;
