import React, { useState } from "react";
import { Button } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import LeftMenuDrawers from "./components/LeftMenuDrawers";
import { useSelector } from "react-redux";
import { pinsSelector } from "../../../../features/senninha/pinsSlice";
import { getTrackingDeliveries, trackingDeliveriesSelector } from "../../../../features/senninha/trackingDeliveriesSlice";
import { useDispatch } from "react-redux";
import { hubsSelector } from "../../../../features/senninha/hubsSlice";

import * as S from "./styles";


const LeftColumn = () => {
    const [open, setOpen] = useState(false)
    const [drawerSelected, setDrawerSelected] = useState('')

    const { filters } = useSelector(pinsSelector);

    const { 
        deliveries
    } = useSelector(trackingDeliveriesSelector)

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const dispatch = useDispatch();

    const validDeliveries = deliveries?.filter((item) => item.status.mensagem !== 'Rejeitada')

    const buttonOptionsMenu = [
        {
            name: "menu-app",
            color: "#303742",
            onClick: () => {
                setDrawerSelected('legend')
                if (open === false || drawerSelected === 'legend') { // fechar o card só se ele estiver com o card especifico aberto
                    setOpen(!open)
                }
            },
            isActive: open && drawerSelected === 'legend',
            solid: true
        },
        {
            name: "filter",
            color: `${filters.filter(value => value.drawer === 'filtro').length ? '#ffffff' : '#303742'}`,
            onClick: () => {
                setDrawerSelected('filter')
                if (open === false || drawerSelected === 'filter') {
                    setOpen(!open)
                }
            },
            isActive: open && drawerSelected === 'filter',
            solid: !filters.filter(value => value.drawer === 'filtro').length || (
                open && drawerSelected === 'filter')
        },
        {
            name: "search",
            color: "#303742",
            onClick: () => {
                setDrawerSelected('search')
                if (open === false || drawerSelected === 'search') {
                    setOpen(!open)
                }
            },
            isActive: open && drawerSelected === 'search',
            solid: true
        },
        {
            name: 'transport-motorcycle',
            color: '#303742',
            onClick: () => {
                setDrawerSelected('delivery')
                if (open === false || drawerSelected === 'delivery') {
                    setOpen(!open)
                    dispatch(getTrackingDeliveries(selectedHubInfos.children))
                }
            },
            isActive: open && drawerSelected === 'delivery',
            solid: true,
            hasDelivery: !!(validDeliveries?.length || 0)
        }
    ];

    return (
        <>
            <LeftMenuDrawers
                open={open}
                setOpen={setOpen}
                drawerSelected={drawerSelected}
                setDrawerSelected={setDrawerSelected}
            />

            <S.LeftContainer>
                <S.LeftContent>
                    {buttonOptionsMenu.map((button, idx) => (
                        <Button
                            key={idx}
                            type={button.solid ? "neutral" : "primary"}
                            solid={button.solid}
                            width="36px"
                            height="36px"
                            onClick={button.onClick}
                        >
                            <GingaIcon
                                name={button.name}
                                color={button.isActive ? "#303742" : button.color}
                            />
                            {button.hasDelivery && (<S.HasDelivery>{validDeliveries?.length}</S.HasDelivery>)}
                        </Button>
                    ))}
                </S.LeftContent>
            </S.LeftContainer>
        </>
    )
}

export default LeftColumn;
