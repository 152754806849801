import React, { useEffect, useState } from 'react';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';

import * as S from './styles';
import { Button } from '@stonelog/stonelog-design-system';


const Paginator = ({ totalPages = 0, currentPage = 1, onChange }) => {
  const [currentPageLocal, setCurrentPageLocal] = useState(currentPage)

  const hasPreviousPage = currentPageLocal > 1
  const hasNextPage = currentPageLocal < totalPages

  const handleNextPage = () => {
    if (hasNextPage) setCurrentPageLocal((p) => p + 1)
  }

  const handlePreviousPage = () => {
    if (hasPreviousPage) setCurrentPageLocal((p) => p - 1)
  }

  useEffect(() => {
    if (onChange) onChange(currentPageLocal)
  }, [currentPageLocal])

  useEffect(() => {
    if (currentPage) setCurrentPageLocal(currentPage)
  }, [currentPage])

  return (
    <S.Paginator>
      <Button
        solid
        radius="8px"
        width="36px"
        height="36px"
      >

        <GingaIcon
          name="chevron-left"
          color={hasPreviousPage ? '#303742' : '#A6AEBA'}
          size={20}
          style={{ cursor: hasPreviousPage ? 'pointer' : 'default' }}
          onClick={handlePreviousPage}
        />
      </Button>
      <p>
        {`${currentPageLocal} de ${totalPages}`}
      </p>
      <Button
        solid
        radius="8px"
        width="36px"
        height="36px"
      >
        <GingaIcon
          name="chevron-right"
          color={hasNextPage ? '#303742' : '#A6AEBA'}
          size={20}
          style={{ cursor: hasNextPage ? 'pointer' : 'default' }}
          onClick={handleNextPage}
        />
      </Button>
    </S.Paginator>
  )
};

export default Paginator;
