import { validateCNPJ, validateCPF } from "validations-br";

export function mascaraCpf(value) {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

export function mascaraHora(value) {
  return value.replace(/(\d{2})(\d{2})/g, "$1:$2");
}

export function mascaraCnpj(value) {
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
}

export function mascaraCep(value) {
  return value.replace(/(\d{5})(\d{3})/g, "$1-$2");
}

export const formatPath = (path) => {
  if (path.length === 11 && validateCPF(mascaraCpf(path))) {
    return mascaraCpf(path);
  }

  if (path.length === 14 && validateCNPJ(mascaraCnpj(path))) {
    return mascaraCnpj(path);
  }

  return path;
};

export const intToReal = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const monthNumToName = (monthNum) => {
  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  return month[monthNum];
};

export const addS = (size, value) => {
  if (!size || size === 1) return value;

  return value + "s";
};

export const formatTreeMappingData = (data) => {
  if (!data) return;

  if (Array.isArray(data) && data.length > 0) {
    return {
      children: data.map((item) => ({
        value: item,
        label: item,
      })),
    };
  }

  if (typeof data === "object") {
    return Object.entries(data).map(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        return {
          value: key,
          label: key,
        };
      }

      const contractorKeys = ["CaçaPOS - Stone", "CaçaPOS - Ton"];

      if (contractorKeys.includes(key)) {
        return {
          [key]: formatTreeMappingData(value),
        };
      }

      return {
        value: key,
        label: key,
        ...formatTreeMappingData(value),
      };
    });
  }
};

export const capitalizeFirstLetter = (str) => {
  str = str.toUpperCase();
  return str.charAt(0) + str.slice(1).toLowerCase();
};

export const capitalizeMultipleFirstLetters = (str) =>
  str
    .toUpperCase()
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");

export const formatTextWithBold = (text) => (
  text?.split('%b%').map((item, index) =>
    index % 2 === 1 ? <b key={index}>{item}</b> : item
  )
)

export const formatArrayToCommaString = (array) => 
  array ? 
    array.length <= 1 
      ? array.join('') 
      : `${array.slice(0, -1).join(', ')} e ${array[array.length - 1]}` 
    : '';

export const formatDateTime = (dateString) => {
  //dateString = "2025-01-02"
  if (!dateString) return "";

  const [ano, mes, dia] = dateString.split("-");
  // return 02/01/2025
  return `${dia}/${mes}/${ano}`;
}

