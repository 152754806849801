import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';
import { manuallyAlocatePin } from './pinsSlice';
import { createDeliveryMockRsponse, simulationListMockResponse } from './tempMocks';
import { uuidToShortString } from '../../utils/common';

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'senninhaManageRoutes';
const GET_BALANCE = 'senninhaManageRoutes/getBalance'
const POST_MANAGEALLROUTES = 'senninhaManageRoutes/postManageAllRoutes';
const POST_SIMULATE_DELIVERIES = 'senninhaManageRoutes/postSimulateDeliveries';
const POST_REQUEST_DELIVERIES = 'senninhaManageRoutes/postRequestDeliveries';


const initialState = {
  balanceData: {
    balance: 0,
    dailyLimit: '',
  },
  simulationsData: null,
  simulationClient: null,
  requestedDeliveriesHashes: null,
  requestDeliveriesOssErrors: null,
  selectedRelocatePins: [],
  selectedPreDeliveryPins: [],
  manageAllRoutesProgress: {
    successes: [],
    errors: [],
    errorsMessages: [],
    retryPayload: [],
  },
  flagOpenTrackDeliveries: false,
  statusGetBalance: 'idle',
  statusManageAllRoutes: 'idle',
  statusSimulatePreDeliveries: 'idle',
  statusRequestPreDeliveries: 'idle',
  errorGetBalance: null,
  unassociatedPinAnyData: {
    unassociatedPin: null,
    deliveriesPins2BeAssociated: null,
  },
  statusManageAllRoutes: "idle",
  errorManageAllRoutes: null,
  errorSimulatePreDeliveries: null,
  errorRequestPreDeliveries: null,
};
// TODO: remover consoles
export const postManageAllRoutes = createAsyncThunk(
  POST_MANAGEALLROUTES,
  async ({ data, batchSize = 3 }, { rejectWithValue, dispatch }) => {
    // const numBatches = Math.ceil(data.length / batchSize);
    const dataLength = data.reduce(
      (total, item) => total + item.pins.length,
      0
    );
    const numBatches = Math.ceil(dataLength / batchSize);
    let changesCount = 0;
    let successes = [];
    let errors = [];
    let errorsMessages = [];
    let errorsAux = [];

    for (const routeData of data) {
      // console.log('routeData', routeData);
      for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
        const batchData = routeData.pins.slice(
          batchIndex * batchSize,
          (batchIndex + 1) * batchSize
        );

        if (batchIndex > 0)
          await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log("batchData", batchData);
        await Promise.all(
          batchData.map(async (item, i) => {
            if (item.servico.toLowerCase() !== "coleta") {
              const payload = {
                id_atendimento:
                  item.tipo === "ANY" ? item.oss[0] : item.id_atendimento,
                contratante: item.contratante,
                angel:
                  routeData.type === "deallocation"
                    ? ""
                    : routeData.angel.email,
                documento:
                  routeData.type === "deallocation"
                    ? ""
                    : routeData.angel.document_number,
                polo: routeData.polo,
                campanha: routeData.campanha,
                oss: item.oss,
              };

              const today = new Date();
              const yesterday = new Date(today);
              yesterday.setDate(yesterday.getDate() - 1);

              const customerDeadlineDate = new Date(
                item.oss[0].customer_deadline_date
              );

              try {
                // TODO: remover mock
                // await new Promise((resolve) => setTimeout(resolve, 1000));
                // if (i === 1 || i === 2) console.log(asd.asd)
                await LogisticService.postWithAuth(
                  `${BASE_URL}/atendimento/senninha/rotas/${routeData.polo}`,
                  payload
                );
                // console.log('mocked req ->', routeData.type, payload, 'res');
                console.log(routeData);

                successes = [...successes, item];
                dispatch(
                  addProgressState({
                    type: "success",
                    data: item,
                  })
                );
                dispatch(
                  manuallyAlocatePin({
                    id: item.id,
                    angel:
                      routeData.type === "deallocation"
                        ? customerDeadlineDate < yesterday
                          ? "Vencidas (Não alocadas)"
                          : customerDeadlineDate > today
                          ? "Vencimento futuros (Não alocadas)"
                          : "Vencem hoje (Não alocadas)"
                        : routeData.angel.nome,
                  })
                );
              } catch (err) {
                console.log("err", err);
                const error = err?.response?.data?.erro || "error";
                errorsMessages.push(`${item.id_atendimento} - ${error}`);
                errors = [...errors, item];
                errorsAux = [...errorsAux, item];
                dispatch(
                  addProgressState({
                    type: "error",
                    data: item,
                  })
                );
              } finally {
                changesCount += 1;
              }
            }
          })
        );

        if (errorsAux.length) {
          dispatch(
            addProgressState({
              type: "retry",
              data: {
                ...routeData,
                ossAmount: errorsAux.reduce(
                  (total, item) => total + item.oss.length,
                  0
                ),
                pins: errorsAux,
              },
            })
          );
          errorsAux = [];
        }
      }
    }

    if (errors.length) {
      return rejectWithValue({ errors, errorsMessages });
    }

    return { successes, errors, errorsMessages };
  }
);

export const getBalance = createAsyncThunk(GET_BALANCE, async (asd, { rejectWithValue }) => {
  try {
    // const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/-/${asd}`);
    const res = await new Promise((resolve) => setTimeout(() => resolve({
      data: {
        saldo: 57.69,
        limite_diario: 'Limite de R$54,90/dia'
      }
    }), 1000))
    return res.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.erro || 'error');
  }
});

export const postSimulatePreDeliveries = createAsyncThunk(POST_SIMULATE_DELIVERIES, async (payload, { rejectWithValue, dispatch }) => {
  try {
    const res = await LogisticService.postWithAuth(
      `${BASE_URL}/any/simular_delivery`,
      payload
    );
    dispatch(resetRequestPreDeliveriesStatus())
    return res.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || err?.response?.data?.error || 'error');
  }
});

export const postRequestPreDeliveries = createAsyncThunk(POST_REQUEST_DELIVERIES, async (data, { rejectWithValue }) => {
  try {
    const res = await LogisticService.postWithAuth(`${BASE_URL}/any/criar_delivery`, data);

    const resFormatted = await Promise.all(res.data.map(async (item) => await uuidToShortString(item.referenceKey)))
    const ossErrors = res.data.reduce((res, item) => 
      item?.erros_parciais_oss?.length ? 
        [...res, ...item.erros_parciais_oss.map((err) => err)] 
        : res
    , [])

    return {
      badgesNumbers: resFormatted,
      ossErrors: ossErrors.length ? ossErrors : null
    };
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err?.response?.data?.error || err?.response?.data?.title || 'error');
  }
});

export const routesManagementSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    manageSelectedRelocatePins: (state, action) => {
      const { id, id_atendimento, tipo, oss, servico, associatedPinsIDs } = action.payload;

      const pinAlreadySelected = state.selectedRelocatePins.find((item) => item.id === id)
      
      if (pinAlreadySelected) {
        const sameOSsLength = oss.length === pinAlreadySelected.oss.length
        const pinAdq = tipo !== 'any' && id_atendimento === pinAlreadySelected.id_atendimento
        const isCollectPin = servico.toLowerCase() === 'coleta'

        if (sameOSsLength || pinAdq || isCollectPin) {
          // console.log('a')
          state.selectedRelocatePins = state.selectedRelocatePins.filter((item) => item.id !== id)

          if (servico.toLowerCase() === 'coleta' && associatedPinsIDs?.length) {
            state.selectedRelocatePins = state.selectedRelocatePins.filter((item) => !associatedPinsIDs.includes(item.id))
          }
      } else {
          // console.log('b')
          state.selectedRelocatePins = state.selectedRelocatePins.map(
            (item) => item.id === id ? 
              action.payload 
              : item
            )
          state.selectedRelocatePins = state.selectedRelocatePins.filter((item) => item.oss.length !== 0)
        }
      } else {
        // console.log('c')
        state.selectedRelocatePins = [
          ...state.selectedRelocatePins, 
          servico.toLowerCase() === 'coleta' ? { ...action.payload, oss: [] } : action.payload
        ]
      }
    },
    resetSelectedRelocatePins: (state) => {
        state.selectedRelocatePins = []
    },
    addSelectedPreDeliveryPins: (state, action) => {
      const { pins, shouldReset } = action.payload;
      state.selectedPreDeliveryPins = [...(shouldReset ? [] : state.selectedPreDeliveryPins), ...pins]
    },
    removeSelectedPreDeliveryPins: (state, action) => {
        state.selectedPreDeliveryPins = state.selectedPreDeliveryPins.filter((item) => item.id_atendimento !== action.payload)
    },
    resetSelectedPreDeliveryPins: (state) => {
        state.selectedPreDeliveryPins = []
    },
    addProgressState: (state, action) => {
      const { type, data } = action.payload;
      console.log("addProgressState", type, data);
      if (type === "success") {
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          successes: [...state.manageAllRoutesProgress.successes, data],
        };
      } else if (type === "error") {
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          errors: [...state.manageAllRoutesProgress.errors, data],
        };
      } else {
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          retryPayload: [...state.manageAllRoutesProgress.retryPayload, data],
        };
      }
    },
    deleteSimulationData: (state, action) => {
      const aux = {
        ...state.simulationsData,
        simulacoes: state.simulationsData.simulacoes.filter((item) => 
          item.id_simulacao !== action.payload
        )
      }
      if (!aux.simulacoes.length) {
        state.statusSimulatePreDeliveries = 'idle'
        state.simulationsData = null
        state.selectedPreDeliveryPins = []
      } else state.simulationsData = aux
    },
    resetRoutesManagementStates: (state) => {
      (state.manageAllRoutesProgress = {
        successes: [],
        errors: [],
        errorsMessages: [],
        retryPayload: [],
      }),
        (state.statusManageAllRoutes = "idle");
      state.errorManageAllRoutes = null;
    },
    resetRoutesManagementStatus: (state) => {
      state.statusManageAllRoutes = "idle";
    },
    resetRoutesManagementSlice: () => {
      state = initialState;
    },
    setUnassociatedPinAnyData: (state, action) => {
      state.unassociatedPinAnyData = action.payload;
    },
    resetUnassociatedPinAnyData: (state) => {
      state.unassociatedPinAnyData = {
        unassociatedPin: null,
        deliveriesPins2BeAssociated: null,
      };
    },

    resetSimulatePreDeliveriesStatus: (state) => {
      state.statusSimulatePreDeliveries = 'idle'
    },
    resetRequestPreDeliveriesStatus: (state) => {
      state.statusRequestPreDeliveries = 'idle'
    },
    setFlagOpenTrackDeliveries: (state, action) => {
      state.flagOpenTrackDeliveries = action.payload
    },
    resetRoutesManagementSlice: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.statusGetBalance = 'pending';
        state.errorGetBalance = null;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        const { saldo: balance, limite_diario: dailyLimit } = action.payload
        state.balanceData = {
          balance,
          dailyLimit
        }
        state.statusGetBalance = 'fulfilled';
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.errorGetBalance = action.payload;
        state.statusGetBalance = 'rejected';
      })
      .addCase(postManageAllRoutes.pending, (state) => {
        state.statusManageAllRoutes = 'pending';
        state.errorManageAllRoutes = null;
      })
      .addCase(postManageAllRoutes.fulfilled, (state, action) => {
        state.manageAllRoutesProgress = action.payload;
        state.statusManageAllRoutes = "fulfilled";
      })
      .addCase(postManageAllRoutes.rejected, (state, action) => {
        const { errors, errorsMessages } = action.payload;
        state.manageAllRoutesProgress = {
          ...state.manageAllRoutesProgress,
          errors,
          errorsMessages,
        };
        state.errorManageAllRoutes = action.payload;
        state.statusManageAllRoutes = 'rejected';
      })
      .addCase(postSimulatePreDeliveries.pending, (state) => {
        state.simulationsData = null;
        state.statusSimulatePreDeliveries = 'pending';
        state.errorSimulatePreDeliveries = null;
      })
      .addCase(postSimulatePreDeliveries.fulfilled, (state, action) => {
        state.simulationsData = action.payload;
        state.statusSimulatePreDeliveries = 'fulfilled';
      })
      .addCase(postSimulatePreDeliveries.rejected, (state, action) => {
        state.errorSimulatePreDeliveries = action.payload;
        state.statusSimulatePreDeliveries = 'rejected';
      })
      .addCase(postRequestPreDeliveries.pending, (state) => {
        state.errorRequestPreDeliveries = null;
        state.requestDeliveriesOssErrors = null;
        state.statusRequestPreDeliveries = 'pending';
        state.requestedDeliveriesHashes = null;
      })
      .addCase(postRequestPreDeliveries.fulfilled, (state, action) => {
        state.requestedDeliveriesHashes = action.payload.badgesNumbers;
        state.requestDeliveriesOssErrors = action.payload.ossErrors
        state.statusRequestPreDeliveries = 'fulfilled';
      })
      .addCase(postRequestPreDeliveries.rejected, (state, action) => {
        state.errorRequestPreDeliveries = action.payload;
        state.statusRequestPreDeliveries = 'rejected';
        state.requestedDeliveriesHashes = null;
        state.requestDeliveriesOssErrors = null;
      });
  },
});

export const {
  manageSelectedRelocatePins,
  resetSelectedRelocatePins,
  addSelectedPreDeliveryPins,
  removeSelectedPreDeliveryPins,
  resetSelectedPreDeliveryPins,
  addProgressState,
  deleteSimulationData,
  resetRoutesManagementSlice,
  resetRoutesManagementStates,
  resetRoutesManagementStatus,
  resetRequestPreDeliveriesStatus,
  setFlagOpenTrackDeliveries,
  resetSimulatePreDeliveriesStatus,
  setUnassociatedPinAnyData,
  resetUnassociatedPinAnyData,
} = routesManagementSlice.actions;

export const routesManagementSelector = (state) => state.routesManagementSlice;

export default routesManagementSlice.reducer;
