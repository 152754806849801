import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  Drawer,
  Dialog,
  Alert,
} from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import RelocateRoutes from "./RelocateRoutes";
import SwapRoutes from "./SwapRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  applyFilter,
  pinsSelector,
  setBuildFilters,
  setMapWorkflow,
} from "../../../../../../../../../features/senninha/pinsSlice";
import { hubsSelector } from "../../../../../../../../../features/senninha/hubsSlice";
import { getAngels } from "../../../../../../../../../features/senninha/angelsSlice";
import {
  resetSelectedRelocatePins,
  postManageAllRoutes,
  resetRoutesManagementStates,
  resetRoutesManagementStatus,
  resetUnassociatedPinAnyData,
  routesManagementSelector,
  setUnassociatedPinAnyData,
  resetSelectedPreDeliveryPins,
  resetSimulatePreDeliveriesStatus,
  addSelectedPreDeliveryPins,
} from "../../../../../../../../../features/senninha/routesManagementSlice";
import warning from "@assets/icons/Warning.svg";
import machineSuccess from "@assets/imgs/machine-success.svg";
import PreDeliveryRoutes from './PreDeliveryRoutes';
import { resetValidationTechnicianStates } from "../../../../../../../../../features/senninha/preDeliveryFormSlice";

import * as S from "./styles";

const RoutePlanning = ({
  activeKeyTab,
  onClose,
  showNotification,
  setActiveKeyTab
}) => {
  const dispatch = useDispatch();

  const { selectedHubInfos } = useSelector(hubsSelector);

  const { pins, filters } = useSelector(
    pinsSelector
  );

  const {
    unassociatedPinAnyData,
    manageAllRoutesProgress,
    statusManageAllRoutes,
    selectedRelocatePins,
    selectedPreDeliveryPins
  } = useSelector(routesManagementSelector);

  const [newWorkflow, setNewWorkFlow] = useState(true);
  const [selectedOption, setSelectedOption] = useState("realocar");
  const [checkedList, setCheckedList] = useState([]);
  const [partialErrorModalOpen, setPartialErrorModalOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [progressTotal, setProgressTotal] = useState(0);
  const [hasPreDelivery, setHasPreDelivery] = useState(false)

  const successManageRoutesWithPreDeliv = hasPreDelivery && statusManageAllRoutes === 'fulfilled'
  const progress = manageAllRoutesProgress.successes?.length + manageAllRoutesProgress.errors?.length
  const loading = statusManageAllRoutes === "pending";

  const workflowRoutes = {
    realocar: (
      <RelocateRoutes
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        setNewWorkFlow={setNewWorkFlow}
        setRoutes={setRoutes}
      />
    ),
    trocar: (
      <SwapRoutes setNewWorkFlow={setNewWorkFlow} setRoutes={setRoutes} />
    ),
    desalocar: (
      <RelocateRoutes
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        setNewWorkFlow={setNewWorkFlow}
        setRoutes={setRoutes}
        isDeallocate
      />
    ),
    preDelivery:
      <PreDeliveryRoutes
        setNewWorkFlow={setNewWorkFlow}
        setRoutes={setRoutes}
      />
  };

  const resetData = () => {
    setCheckedList([])
    if (activeKeyTab === '1') dispatch(setMapWorkflow('routesDrawer-relocation'))
  }

  const RowInfo = ({ type, rowData }) => {
    const aux = {
      relocation: {
        title: 'Realocação',
        description: `${rowData?.angel?.nome}, ${rowData?.pins?.length} Pins, ${rowData?.ossAmount} OS`,
        iconBg: '#287DF31A',
        iconName: 'avatar',
        iconColor: '#287DF3'
      },
      exchange: {
        title: 'Troca de rotas',
        description: `${rowData?.angel1?.nome} e ${rowData?.angel2?.nome}`,
        iconBg: '#00A8681A',
        iconName: 'handshake',
        iconColor: '#00A868'
      },
      deallocation: {
        title: 'Desalocação',
        description: `${rowData?.pins?.length} Pins, ${rowData?.ossAmount} OS`,
        iconBg: '#F384281A',
        iconName: 'avatar-delete',
        iconColor: '#F38428'
      },
      preDelivery: {
        title: 'Pré-delivery',
        description: `${rowData?.pins?.length} Pins, ${rowData?.ossAmount} OS`,
        iconBg: '#9A79F31A',
        iconName: 'solid-transport-motorcycle',
        iconColor: '#9A79F3'
      }
    }

    return (
      <>
        <Drawer.RowPrefix
          style={{
            backgroundColor: aux[type].iconBg,
            padding: 8,
            borderRadius: "50%",
            height: 32,
          }}
        >
          <GingaIcon
            name={aux[type].iconName}
            color={aux[type].iconColor}
            size={16}
          />
        </Drawer.RowPrefix>
        <Drawer.RowText
          text={aux[type].title}
          description={aux[type].description}
        />
      </>
    );
  };

  const getRouteOptions = () => {
    const routeOptions = [
      {
        label: "Realocar OS",
        value: "realocar",
      },
      {
        label: "Trocar rotas",
        value: "trocar",
      },
      {
        label: "Desalocar OS",
        value: "desalocar",
      }
    ]
    if (!selectedPreDeliveryPins?.length)
      routeOptions.push({
        label: 'Pré-delivery',
        value: 'preDelivery'
      })
  
  return routeOptions;
}

  const areAllAssociatedPinsSelected = () => {
    const selectedPinsMap = selectedRelocatePins.reduce(
      (data, pin) => ({
        ...data,
        [pin.id]: pin,
      }),
      {}
    );

    return selectedRelocatePins.every(
      (pin) =>
        pin.servico !== "COLETA" ||
        pin.associatedPinsIDs.every(
          (id) =>
            selectedPinsMap[id] && selectedPinsMap[id].servico === "ENTREGA"
        )
    );
  };

  const handleDeleteRoute = (i) =>
    setRoutes([...routes.slice(0, i), ...routes.slice(i + 1)]);

  const getOSsCount = (pins) =>
    pins.length
      ? pins.reduce((sum, item) => {
        if (item?.servico?.toLowerCase() === "coleta") return sum; // TODO: aq vai vir OS dps?
        return sum + item?.oss?.length;
      }, 0)
      : 0;

  const getOSsCountFromPayload = (payload) =>
    payload.reduce((sum, item) => {
      const pins =
        item.pins ??
        [...(item.pins1 || [])].concat([...(item.pins2 || [])]) ??
        [];

      const ossCount = pins.reduce((sum2, item2) => {
        if (item2?.servico?.toLowerCase() === "coleta") return sum2; // TODO: aq vai vir OS dps?
        return sum2 + item2.oss.length;
      }, 0);
      return sum + ossCount;
    }, 0);

  const handleManageRoutes = () => {
    setProgressTotal(getOSsCountFromPayload(routes));
    const payload = [].concat(
      ...routes
        .filter(item => item.type !== 'preDelivery')
        .map((item) =>
          item.type === "exchange"
            ? [
              {
                type: item.type,
                angel: item.angel1,
                pins: item.pins2,
                polo: item.polo,
                campanha: item.campanha,
              },
              {
                type: item.type,
                angel: item.angel2,
                pins: item.pins1,
                polo: item.polo,
                campanha: item.campanha,
              },
            ]
            : [{ ...item, campanha: true }]
        )
    );

    dispatch(
      postManageAllRoutes({
        data: payload,
      })
    );
    routes.forEach(({ type, pins }) => {
      if (type === 'preDelivery')
        dispatch(addSelectedPreDeliveryPins({
          pins,
          shouldReset: false,
        }))
    })
  };

  const handleManageRoutesRetry = () => {
    const payload = manageAllRoutesProgress.retryPayload;
    setProgressTotal(getOSsCountFromPayload(payload));
    dispatch(resetRoutesManagementStates());
    dispatch(
      postManageAllRoutes({
        data: payload,
      })
    );
    setPartialErrorModalOpen(false);
  };

  const handlePreDeliveryRedir = () => {
    setActiveKeyTab('2')
    dispatch(resetRoutesManagementStatus())
    setRoutes([]);
  }

  const togglePinsAny = (visibility) => {
    const idx = filters.findIndex(
      (obj) => obj.field === 'tipo' && obj.value === 'adquirencia' && obj.drawer === 'gestaoRotas'
    );

    let filtersAux = [...filters]

    if (visibility === 'show' && idx !== -1)
      filtersAux.splice(idx, 1);
    if (visibility === 'hide' && idx === -1)
      filtersAux = [
        ...filters,
        { field: 'tipo', value: 'adquirencia', drawer: 'gestaoRotas' }
      ]

    dispatch(setBuildFilters(filtersAux))
    dispatch(applyFilter())
  }

  useEffect(() => {
    dispatch(getAngels(selectedHubInfos.children));
  }, []);

  useEffect(() => {
    // dispatch(getBalance()) // so na v3 
    dispatch(resetRoutesManagementStates());
    dispatch(resetSelectedRelocatePins());
    dispatch(resetSelectedPreDeliveryPins())
    dispatch(resetSimulatePreDeliveriesStatus())
    dispatch(resetValidationTechnicianStates())

    return () => {
      dispatch(setMapWorkflow("infoDrawer"));
      dispatch(resetSelectedRelocatePins());
      dispatch(resetRoutesManagementStates());
      dispatch(resetSelectedPreDeliveryPins())
      dispatch(resetSimulatePreDeliveriesStatus())
      dispatch(resetValidationTechnicianStates())
    };
  }, []);

  useEffect(() => {
    dispatch(resetSelectedRelocatePins());
    setCheckedList([]);
    dispatch(resetUnassociatedPinAnyData());
  }, [selectedOption, routes]);

  useEffect(() => {
    if (activeKeyTab === '1') {
      if (['realocar', 'preDelivery'].includes(selectedOption))
        dispatch(setMapWorkflow('routesDrawer-relocation'))
      if (selectedOption === 'trocar')
        dispatch(setMapWorkflow('routesDrawer-exchange'))
      else setMapWorkflow('routesDrawer')

      resetData()
    }
  }, [newWorkflow, selectedOption, activeKeyTab])

  useEffect(() => {
    if (selectedRelocatePins.length) {
      const selectedCollectPins = pins.filter(
        (item) =>
          item.associatedPinsIDs &&
          selectedRelocatePins.find((item2) => item2.id === item.id)
      );

      const collectPinsUnallocated = selectedCollectPins.filter(
        ({ associatedPinsIDs }) =>
          !selectedRelocatePins.find((item) =>
            associatedPinsIDs.includes(item.id)
          )
      );

      if (collectPinsUnallocated.length) {
        dispatch(
          setUnassociatedPinAnyData({
            unassociatedPin: collectPinsUnallocated[0],
            deliveriesPins2BeAssociated: pins.filter((item) =>
              collectPinsUnallocated[0]?.associatedPinsIDs.includes(item.id)
            ),
          })
        );
      }
      const selectedIds = unassociatedPinAnyData.deliveriesPins2BeAssociated?.reduce(
        (data, item) => {
          const pinSelected = selectedRelocatePins.filter(
            (item2) => item2.id === item.id
          );
          if (pinSelected.length) return [...data, item.id];
          return data;
        },
        []
      );
      if (selectedIds) setCheckedList(selectedIds);

      if (areAllAssociatedPinsSelected()) {
        resetData();
      }
    } else resetData();
  }, [selectedRelocatePins]);

  useEffect(() => {
    const lastSelectedPin =
      selectedRelocatePins[selectedRelocatePins.length - 1];
    if (unassociatedPinAnyData.unassociatedPin && lastSelectedPin) {
      const isLastPinAssociated = unassociatedPinAnyData.unassociatedPin.associatedPinsIDs.includes(
        lastSelectedPin.id
      );
      const lastPinIsNotTheUnassociated =
        isLastPinAssociated &&
        isLastPinAssociated.id !== unassociatedPinAnyData.unassociatedPin.id;

      if (
        lastSelectedPin?.tipo?.toLowerCase() !== "any" ||
        (!isLastPinAssociated && lastPinIsNotTheUnassociated)
      ) {
        resetData();
      }
    }
  }, [selectedRelocatePins]);

//   useEffect(() => {
//         if (selectedRelocatePins.length) {
//             const selectedCollectPins = pins.filter((item) => 
//                 item.associatedPinsIDs && selectedRelocatePins.find((item2) => item2.id === item.id)
//             )
//             const collectPinsUnallocated = selectedCollectPins.filter(({ associatedPinsIDs }) => 
//                 !selectedRelocatePins.find((item) => associatedPinsIDs.includes(item.id)
//             ))

//             if (collectPinsUnallocated.length) {
//                 setUnassociatedPinAny(collectPinsUnallocated[0])            
//                 setDeliveriesPins2BeAssociated(pins.filter((item) => collectPinsUnallocated[0]?.associatedPinsIDs.includes(item.id)))
//             }
//             const selectedIds = deliveriesPins2BeAssociated.reduce((data, item) => {
//                 const pinSelected = selectedRelocatePins.filter((item2) => item2.id === item.id)
//                 if (pinSelected.length) return [...data, item.id]
//                 return data
//             }, [])
//             setCheckedList(selectedIds)

//             if (areAllAssociatedPinsSelected()) {
//                 resetData()
//             }
//         } else resetData()      
//     }, [selectedRelocatePins])

//     useEffect(() => {
//         const lastSelectedPin = selectedRelocatePins[selectedRelocatePins.length-1]
//         if (unassociatedPinAny && lastSelectedPin) {
//             const isLastPinAssociated = unassociatedPinAny.associatedPinsIDs.includes(lastSelectedPin.id)
//             const lastPinIsNotTheUnassociated = isLastPinAssociated && isLastPinAssociated.id !== unassociatedPinAny.id

//             if (lastSelectedPin.tipo.toLowerCase() !== 'any' || (!isLastPinAssociated && lastPinIsNotTheUnassociated)) {
//                 resetData()
//             }
//         }
//     }, [selectedRelocatePins])

  useEffect(() => {
    if (selectedOption === 'preDelivery')
      togglePinsAny('hide')
    else togglePinsAny('show')

    return (() => {
      togglePinsAny('show')
    })
  }, [selectedOption])

  useEffect(() => {
    setHasPreDelivery(!!routes.find((item) => item.type === 'preDelivery'))
    if (statusManageAllRoutes === 'fulfilled') {
      setMapWorkflow('routesDrawer')
    }
  }, [routes, statusManageAllRoutes])

  useEffect(() => {
    if (statusManageAllRoutes === 'fulfilled') {
      if (filters.length) dispatch(applyFilter(filters));
      dispatch(resetSelectedRelocatePins())
      resetData()
      if (!hasPreDelivery) {
        onClose()
        dispatch(resetRoutesManagementStatus())
        showNotification('Operação realizada com sucesso!')
      }
    }
    if (statusManageAllRoutes === 'rejected') {
      setPartialErrorModalOpen(true)
      dispatch(resetRoutesManagementStatus())
    }
  }, [statusManageAllRoutes])

  return (
    <>
      <Dialog.Root
        open={partialErrorModalOpen}
        onCancel={() => setPartialErrorModalOpen(false)}
        centered
      >
        <Dialog.Header title="Erro" />

        <Dialog.ContentHeader
          ilustration={<img src={warning} />}
          description={`Não conseguimos realocar pois encontramos falhas em ${getOSsCount(
            manageAllRoutesProgress.errors
          )} de ${progressTotal} itens. Deseja tentar novamente?`}
        />

        <Dialog.ContentDetails
          details={manageAllRoutesProgress.errorsMessages || []}
        />

        <Dialog.Footer>
          <Button
            onClick={() => {
              setPartialErrorModalOpen(false);
              dispatch(resetRoutesManagementStates());
            }}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            loading={statusManageAllRoutes === "pending"}
            onClick={handleManageRoutesRetry}
          >
            Tentar novamente
          </Button>
        </Dialog.Footer>
      </Dialog.Root>
      <Drawer.HeaderActions className="ginga-lib-header-actions">
        <Button
          type="primary"
          size="small"
          disabled={newWorkflow}
          onClick={() => {
            setNewWorkFlow(true);
            setSelectedOption("realocar");
          }}
        >
          <GingaIcon size="16px" name="round-add" color="#FFF" />
          Adicionar nova função
        </Button>
        {/* <S.BodyTopRightContent> ----->>> V1
            <Tag
                iconName='transport-motorcycle'
                text='R$380,00'
                type='success'
            />
            <p>
                Gaste hoje R$54,90
            </p>
        </S.BodyTopRightContent> */}
      </Drawer.HeaderActions>
      <Drawer.Body>
        {loading ? (
          <>
            <Drawer.Loading
              loadingMessage={`Aguarde um momento, realocando... ${getOSsCount(
                manageAllRoutesProgress.successes.concat(
                  manageAllRoutesProgress.errors
                )
              )} de ${progressTotal} já foram concluídos.`}
            />
          </>
        ) : successManageRoutesWithPreDeliv ? (
          <Drawer.Response
            element={<img src={machineSuccess} />}
            message="Operação realizada com sucesso!"
            suffixElement={
              <Alert
                description="Para fazer a simulação dos pins colocados em pré-delivery vá para lista de pré-delivery."
                type="info"
                style={{ marginTop: '1rem' }}
              />
            }
          />
        ) : (
          <>
            {newWorkflow && (
              <S.BodyMiddleContainer>
                <Select
                  label="Função"
                  options={getRouteOptions()}
                  placeholder="Selecione uma opção"
                  defaultValue="realocar"
                  onChange={(e) => {
                    setSelectedOption(e);
                  }}
                  size="small"
                />

                {workflowRoutes[selectedOption]}
              </S.BodyMiddleContainer>
            )}

            {routes?.map((item, i) => (
              <Drawer.Row key={i}>
                <RowInfo type={item.type} rowData={item} />
                <Drawer.RowActions>
                  <Button
                    type="negative"
                    solid
                    radius="99px"
                    width="36px"
                    height="36px"
                    onClick={() => handleDeleteRoute(i)}
                  >
                    <GingaIcon name="trash" color="#E6171E" size={20} />
                  </Button>
                </Drawer.RowActions>
              </Drawer.Row>
            ))}
          </>
        )}
      </Drawer.Body>

      {!loading &&
        <Drawer.Footer>
          <Button
            type='primary'
            width='100%'
            height='52px'
            size='large'
            onClick={successManageRoutesWithPreDeliv ?
              handlePreDeliveryRedir
              : handleManageRoutes
            }
            disabled={successManageRoutesWithPreDeliv ? false : !routes.length}
            className="ginga-lib-button"
          >
            {successManageRoutesWithPreDeliv ?
              'Ir para lista de pré-delivery'
              : 'Finalizar'
            }
          </Button>

          <Button
            width='100%'
            height='52px'
            size='large'
            onClick={() => {
              onClose()
            }}
            className="ginga-lib-button"
          >
            Cancelar
          </Button>
        </Drawer.Footer>
      }
    </>
  );
};

export default RoutePlanning;
