import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnproductiveTable from "./TeamManagement/UnproductivityJustification/UnproductiveTable";
import { unproductivityJustificationSelector } from "../../../../features/senninha/GestaoEquipe/unproductivityJustificationSlice";

import * as S from "./styles";


const NewSenninha = () => {

    const {
        unproductivityJustificationData,
    } = useSelector(unproductivityJustificationSelector);

    return (
        <>
            {unproductivityJustificationData?.length !== 0 && (
                <div>
                    <S.AllPageAlertSenninhaTeam>
                        <UnproductiveTable senninhaScreen />
                    </S.AllPageAlertSenninhaTeam>
                </div>
            )}
        </>
    );
};

export default NewSenninha;
