import styled from "styled-components";


export const AllPageTeamController = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px 32px;
`;

export const HeaderPageTeamController = styled.div`
    display: flex;
    justify-content: space-between;
`;