import React from "react";
import { Alert, Tag } from "@stonelog/stonelog-design-system";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import * as S from "./styles";
import { capitalizeAllFirstLetters } from "../../../../../../consumingApi/services/helper";

const HoverContainer = ({ dataPin }) => {
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = parseISO(dateStr);
    return format(date, "dd'/'MM'/'y", { locale: ptBR });
  };

  return (
    <S.HoverContainer>
      <S.HoverHeader background={dataPin?.stylePin?.color}>
        <p>{dataPin.angel}</p>
      </S.HoverHeader>
      <S.HoverContent>
        <S.HoverContentTextTagged>
          <div>
            <span>Cliente</span>
            <p>{dataPin.cliente}</p>
          </div>

          {dataPin?.tag?.tipo && (
            <div>
              <Tag text={dataPin?.tag.mensagem} type={dataPin?.tag.tipo} />
            </div>
          )}
        </S.HoverContentTextTagged>
        <S.HoverContentText>
          <div>
            <span>Endereço</span>
            <p>{`${dataPin?.endereco}, ${
              dataPin?.numero_endereco
            } - ${capitalizeAllFirstLetters(dataPin?.oss[0]?.referencia)},
                         ${capitalizeAllFirstLetters(
                           dataPin?.oss[0]?.cidade
                             ? dataPin?.oss[0]?.cidade
                             : dataPin?.cidade
                         )} - ${dataPin?.uf}, ${dataPin?.cep}`}</p>
          </div>
        </S.HoverContentText>
        {dataPin?.oss[0].horario_funcionamento && (
          <S.HoverContentText>
            <div>
              <span>Horário de funcionamento</span>
              <p>{dataPin?.oss[0].horario_funcionamento}</p>
            </div>
          </S.HoverContentText>
        )}
        {dataPin.tipo === "any" ? (
          <S.HoverContentText>
            <div>
              <span>Data limite</span>
              <p>{formatDate(dataPin?.oss[0].deadline_date)}</p>
            </div>

            <div>
              <span>Tracking code</span>
              <p>{dataPin.id_atendimento}</p>
            </div>
          </S.HoverContentText>
        ) : (
          <>
            <S.HoverContentText>
              <div>
                <span>Stonecode</span>
                <p>{dataPin.id_atendimento}</p>
              </div>

              <div>
                <span>Qnt de OS</span>
                <p>{dataPin.volume_oss}</p>
              </div>
            </S.HoverContentText>
            {dataPin.volume_oss > 2 && (
                <Alert
                  description="Esse atendimento possui muitas OSs, clique no pin para mais informações."
                  type="info"
                  className="ginga-lib-alert"
                />
            )}
            {dataPin.volume_oss <= 2 && dataPin.servico !== "0TPV"
              ? dataPin.oss?.map((os, idx) => {
                  return (
                    <S.HoverContentText key={idx}>
                      <div>
                        <span>Data limite cliente</span>
                        <p>{formatDate(os?.customer_deadline_date)}</p>
                      </div>

                      <div>
                        <span>Nº da OS</span>
                        <p>{os.id_os}</p>
                      </div>
                    </S.HoverContentText>
                  );
                })
              : dataPin?.aviso?.mensagem && (
                  <Alert
                    description={dataPin.aviso.mensagem}
                    type={dataPin.aviso.tipo}
                  />
                )}
          </>
        )}
      </S.HoverContent>
    </S.HoverContainer>
  );
};

export default HoverContainer;
