import styled from "styled-components";


export const UnproductiveFilterContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 16px;
   border-bottom: 1px solid rgba(118, 127, 141, 0.1);
`;

export const UnproductiveFilterColumn = styled.div`
   display: flex;
   align-items: center;
   gap: 2px;
`;

export const LeftUnproductiveFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const LeftUnproductiveSelectFilter = styled.div`
    display: flex;
    gap: 12px;
`;

export const LeftUnproductiveButtonFilter = styled.div`
    display: flex;
    gap: 8px;
`;

export const RightUnproductiveFilter = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px 32px;
    margin-top: 12px;
`;

export const StringDesign = styled.div`
    span{
        overflow: hidden;
        color: var(--Content-Neutral-High, #303742);
        text-overflow: ellipsis;

        /* Body/Medium -> Regular */
        font-family: "Sharon Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 21.75px */
        letter-spacing: -0.15px;
    }
    p{
        overflow: hidden;
        color: var(--Content-Neutral, #767F8D);
        text-overflow: ellipsis;

        /* Body/Small -> Regular */
        font-family: "Sharon Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 18.85px */
        letter-spacing: -0.13px;
    }
`