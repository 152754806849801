import styled from "styled-components";


export const BodyTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 12px 24px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`;

export const PreDeliveryLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PreDeliveryBadge = styled.div`
  background-color: #E6171E;
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;