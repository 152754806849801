import React, { useEffect } from "react";
import { Card, Select, Table } from "@stonelog/stonelog-design-system";
import { usePaginationConfig } from "@hooks/usePaginationConfig";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { useState } from "react";
import { compareTableColumn } from "../../../../../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { frequencyHistoricSelector, getFrequencyHistoric } from "../../../../../../../features/senninha/GestaoEquipe/frequencyHistoricSlice";
import FrequencyDrawer from "./FrequencyDrawer";
import SelectDatetime from "@components/SelectDatetime";
import { getHubs, hubsSelector } from "../../../../../../../features/senninha/hubsSlice";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";

import * as S from "./styles";


const FrequencyHistory = () => {

    const [selectedMonth, setSelectedMonth] = useState("")
    const [open, setOpen] = useState(false);
    const [rowClickedInfos, setRowClickedInfos] = useState({});
    const [selectedCollaborator, setSelectedCollaborator] = useState(null);

    const paginationConfig = usePaginationConfig([10, 10]);

    const dispatch = useDispatch()

    const {
        frequencyHistoricData,
        frequencyHistoricStatus
    } = useSelector(frequencyHistoricSelector);

    const {
        selectedDistrictInfos,
        districts,
        selectedHubInfos,
        hubs
    } = useSelector(hubsSelector);

    const [hub, setHub] = useState(selectedHubInfos?.children)
    const [district, setDistrict] = useState(selectedDistrictInfos?.children)

    useEffect(() => {
        if (selectedMonth) {
            dispatch(getFrequencyHistoric({
                polo: hub,
                distrito: district,
                mes: selectedMonth?.value
            }))
        }
    }, [selectedMonth, selectedDistrictInfos, hub]);

    const getAngelsOptions = () => frequencyHistoricData.map(({ nome }) => ({
        value: nome,
        label: nome
    }))

    const calculatePresencePercentage = (faltas, totalDias) => {
        const diasPresentes = totalDias - faltas;
        return Math.round((diasPresentes / totalDias) * 100); // Usa Math.round para arredondar
    };

    const getDistrictsOptions = () => districts.map(({ name, id }) => ({
        value: name,
        label: name,
        children: name,
        id: id
    }))

    const getHubsOptions = () => hubs.map(({ name, id }) => ({
        value: name,
        label: name,
        children: name,
        id: id
    }))

    const onChangeDistrict = (value, component) => {
        dispatch(getHubs({
            id_distrito: component.id
        }))
        setDistrict(value)
        setHub(null)
        dispatch(getFrequencyHistoric({
            distrito: value,
        }))
    }

    const onChangeHub = (value) => {
        setHub(value)
        dispatch(getFrequencyHistoric({
            polo: value,
            distrito: district,
        }))
    }

    const dataSource = frequencyHistoricData?.map((item, idx) => ({
        key: idx,
        nome: item.nome,
        distrito: item.distrito,
        polo: item.polo_origem,
        faltas: item.faltas,
        presenca: calculatePresencePercentage(item.faltas, item.count_workdays_in_month) + "%",
        email: item.email
    })).filter(item =>
        !selectedCollaborator || item.nome === selectedCollaborator
    );

    const columns = [
        {
            title: 'Colaborador',
            dataIndex: 'nome',
            sorter: {
                compare: (a, b) => compareTableColumn(a, b, "colaborador"),
            },
            key: 'nome',
            render: (value, record) => {
                return value ? (
                    <S.StringDesign>
                        <span>
                            {capitalizeAllFirstLetters(value)}
                        </span>
                        <p>
                            {record.email}
                        </p>
                    </S.StringDesign>
                ) : (
                    <div>-</div>
                );
            },
        },
        {
            title: 'Distrito',
            dataIndex: 'distrito',
            key: 'distrito',
        },
        {
            title: 'Polo',
            dataIndex: 'polo',
            key: 'polo',
        },
        {
            title: 'Total de faltas no mês',
            dataIndex: 'faltas',
            key: 'faltas',
        },
        {
            title: '% de presença no mês',
            dataIndex: 'presenca',
            sorter: {
                compare: (a, b) => compareTableColumn(a, b, "presenca"),
            },
            key: 'presenca',
        },
    ]

    return (
        <>
            <FrequencyDrawer
                open={open}
                setOpen={setOpen}
                rowClickedInfos={rowClickedInfos}
                setSelectedMonth={setSelectedMonth}
                selectedMonth={selectedMonth}
            />
            <Card.Root>
                <Card.Header>
                    <Card.HeaderText
                        title={`Histórico de Frequência (${frequencyHistoricData?.length}) - ${selectedMonth?.label}`}
                        subtitle={`Acompanhe a frequência mensal dos colaboradores e, para mais detalhes, clique no nome para visualizar o histórico completo.`}
                    />
                    <Card.HeaderActions>
                        <SelectDatetime
                            selectedMonth={selectedMonth}
                            setSelectedMonth={setSelectedMonth}
                        />
                    </Card.HeaderActions>
                </Card.Header>
                <S.FrequencyFilterContainer>
                    <S.LeftFrequencyFilter>
                        <S.LeftFrequencySelectFilter>
                            <Select
                                prefixIcon={
                                    <GingaIcon
                                        name="location"
                                        color="rgba(118, 127, 141, 1)"
                                        size={21}
                                    />
                                }
                                onChange={onChangeDistrict}
                                options={getDistrictsOptions()}
                                value={selectedDistrictInfos?.children}
                                placeholder="Selecione uma opção"
                                label="Distrito"
                                size="small"
                            />
                            <Select
                                prefixIcon={
                                    <GingaIcon
                                        name="location"
                                        color="rgba(118, 127, 141, 1)"
                                        size={21}
                                    />
                                }
                                onChange={onChangeHub}
                                options={getHubsOptions()}
                                value={hub}
                                placeholder="Selecione uma opção"
                                label="Polo"
                                size="small"
                                allowClear
                            />
                            <Select
                                prefixIcon={
                                    <GingaIcon
                                        name="avatar"
                                        color="rgba(118, 127, 141, 1)"
                                        size={21}
                                    />
                                }
                                loading={frequencyHistoricStatus === 'pending'}
                                options={getAngelsOptions()}
                                onChange={setSelectedCollaborator}
                                placeholder="Selecione uma opção"
                                label="Colaborador"
                                allowClear
                                size="small"
                            />
                        </S.LeftFrequencySelectFilter>
                    </S.LeftFrequencyFilter>
                </S.FrequencyFilterContainer>
                <Card.Body
                    className="ginga-lib-card-body"
                >
                    <Table
                        paginationConfig={paginationConfig}
                        columns={columns}
                        dataSource={dataSource}
                        loading={frequencyHistoricStatus === 'pending'}
                        className="ginga-lib-table"
                        onRow={(row) => ({
                            onClick: () => {
                                setRowClickedInfos(row)
                                setOpen(true)
                            },
                        })}
                    />
                </Card.Body>
            </Card.Root>
        </>

    );
};

export default FrequencyHistory;
