import styled from "styled-components";


export const AllPageTeamUnproductive = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px 32px;
`;

export const ContentPageTeamUnproductive = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

