import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';
import { senninhaColors } from '../../utils/common';

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'pinsSenninha';
const GET_PINS = 'pinsSenninha/getPins';
const GET_HUNTER = 'pinsSenninha/getPinsHunter';
const GET_HUNTER_STONE = 'pinsSenninha/getPinsHunterStone';
const GET_SERVICE_DETAILS = 'pinsSenninha/serviceDetails';


const initialState = {
    pins: [],
    filteredPins: [],
    pinsStatus: 'idle',
    serviceDetails: {},
    serviceDetailsStatus: 'idle',
    pinZeroCoordenadas: { lat: 0.0, lng: 0.0 },
    center: { lat: 0.0, lng: 0.0 },
    zoom: 10,
    filters: [],
    openDrawer: true,
    leftOpenDrawer: false,
    mapWorkflow: 'infoDrawer',
    hunterTonStatus: 'idle',
    hunterStoneStatus: 'idle',
    bouncedPin: ''
};

export const getPins = createAsyncThunk(GET_PINS, async ({ provider }, { rejectWithValue }) => {
    const getColorAndIcon = (angel, angelColors) => {

        switch (angel) {
            case 'Vencidas (Não alocadas)':
                return { color: '#E6171E', name: 'calendar' };
            case 'Vencem hoje (Não alocadas)':
                return { color: '#F38428', name: 'notification' };
            case 'Vencimento futuros (Não alocadas)':
                return { color: '#0057D9', name: 'calendar' };
            case 'Baixadas':
                return { color: '#0BA749', name: 'calendar-check' };
            case 'Comercial Redirect':
                return { color: '#E6B91A', name: 'device-card-machine' };
            case 'DELIVERY':
                return { color: '#767F8D', name: 'transport-motorcycle' };
            case 'LOGGI':
                return { color: '#00BAFF', name: 'transport-motorcycle' };
            case 'OS Noturna':
                return { color: '#000000', name: 'moon' };
            default:
                if (!angelColors[angel]) {
                    let color;
                    do {
                        color = getRandomColor();
                    } while (Object.values(angelColors).includes(color));
                    angelColors[angel] = color;
                    return { color: color, name: 'avatar' };
                }
                return { color: angelColors[angel], name: 'avatar' };
        }
    }

    const getRandomColor = () => senninhaColors[Math.floor(Math.random() * senninhaColors.length)]

    const setAssociatedPinsAny = (pins) => pins.reduce((data, item) => {
        if (item?.servico?.toLowerCase() === 'coleta') {
            const associatedPinsIDs = pins.reduce((data2, item2) =>
                (item2.id_atendimento === item.id_atendimento && item2?.servico?.toLowerCase() === 'entrega') ?
                    [...data2, item2.id]
                    : data2,
                [])
            return [...data, { ...item, associatedPinsIDs }]
        }
        return [...data, item]
    }, [])

    // TEMP fix until new overlap pins flow
    const fixSameCoords = (pins) => {
        const coords = {};
    
        pins.forEach((item) => {
            const key = `${item.lat},${item.lng}`;
            if (!coords[key]) {
                coords[key] = [];
            }
            coords[key].push(item);
        });
    
        Object.values(coords).forEach((sameCoordPin) => {
            const pinsAmount = sameCoordPin.length;

            if (pinsAmount > 1) {
                const middleIndex = Math.floor(pinsAmount / 2);
                sameCoordPin.forEach((pin, index) => {
                    pin.lng += (index - middleIndex) * 0.00008;
                });
            }
        });
    
        return pins;
    };    

    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/rotas/${provider}`);
        const angelColors = {};
        const pinsWithStyles = res?.data?.map((pin, i) => ({
            id: i,
            ...pin,
            lat: parseFloat(pin.lat),
            lng: parseFloat(pin.lng),
            stylePin: getColorAndIcon(pin.angel, angelColors)
        }))

        return fixSameCoords(setAssociatedPinsAny(pinsWithStyles));
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});

export const getPinsHunterTon = createAsyncThunk(GET_HUNTER, async ({ provider }, { rejectWithValue }) => {

    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/rotas_0tpv_ton/${provider}`);

        return res

    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});

export const getPinsHunterStone = createAsyncThunk(GET_HUNTER_STONE, async ({ provider }, { rejectWithValue }) => {

    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/rotas_0tpv/${provider}`);

        return res

    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});

export const getAllPinsData = createAsyncThunk('getAllPinsData', async ({ provider }, { dispatch }) => {
    try {

        const results = await Promise.allSettled([
            dispatch(getPins({ provider })),
            dispatch(getPinsHunterTon({ provider })),
            dispatch(getPinsHunterStone({ provider }))
        ]);

        const pinsResult = results[0];
        const hunterTonResult = results[1];
        const hunterStoneResult = results[2];

        const payload = {
            pins: !pinsResult.value.error ? pinsResult.value.payload : [],
            hunterTon: !hunterTonResult.value.error ? hunterTonResult.value.payload.data : [],
            hunterStone: !hunterStoneResult.value.error ? hunterStoneResult.value.payload.data : []
        };

        return payload;
    } catch (error) {
        console.error('Failed to fetch data from one or both endpoints:', error);
        return { pins: [], hunterTon: [], hunterStone: [] };
    }
});

export const getServiceDetails = createAsyncThunk(GET_SERVICE_DETAILS, async ({ id, id_atendimento, provider, servico, id_os }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(setServiceDetails({
            pinDetails: null,
            pinIdentifiers: {
                id,
                servico,
                id_os
            }
        }))
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/detalhamento/${provider}/${id_atendimento}`);

        return {
            pinDetails: {
                id,
                ...(res.data?.find((item) => item.oss.find((item2) => item2.id_os === id_os)) || {})
            },
            pinIdentifiers: {
                id,
                servico,
                id_os
            }
        };
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});

const filterPinByType = (filter, pin) => {
    if (filter.field === 'tecnologias') { //array
        const hasFilter = pin[filter.field].filter((value) => value === filter.value)
        return hasFilter.length > 0

    } else if (filter.field === 'deadline_date') { //array de objectos
        const hasFilterByDate = pin.oss.map((os) =>
            filter.arrayDates.some((value) => value === os.deadline_date)
        );
        return hasFilterByDate.includes(true);

    } else if (filter.field === 'customer_deadline_date') { //array de objectos
        const hasFilterByDate = pin.oss.map((os) =>
            filter.arrayClientDates.some((value) => value === os.customer_deadline_date)
        );
        return hasFilterByDate.includes(true);

    } else { //string
        return pin[filter.field] === filter.value
    }
}

export const pinsSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        setCenter: (state, action) => {
            state.center = { ...action.payload.center };
        },
        setZoom: (state, action) => {
            state.zoom = action.payload;
        },
        setBouncePin: (state, action) => {
            state.bouncedPin = action.payload
        },
        applyFilter: (state) => {

            const legendaFilters = state.filters.filter(f => f.drawer === 'legenda');
            const filtrosFilters = state.filters.filter(f => f.drawer === 'filtro');
            const routeManagementFilters = state.filters.filter(f => f.drawer === 'gestaoRotas');

            const groupByField = (filters) => {
                //acc -> Acumulador
                return filters.reduce((acc, filter) => {
                    if (!acc[filter.field]) {
                        acc[filter.field] = [];
                    }
                    acc[filter.field].push(filter);
                    return acc;
                }, {});
            };

            const filterPinsByFieldGroups = (pin, fieldGroups) => {
                return Object.values(fieldGroups).every(filtersGroup =>
                    filtersGroup.some(filter => filterPinByType(filter, pin))
                );
            };

            // const legendaFieldGroups = groupByField(legendaFilters);
            const filtrosFieldGroups = groupByField(filtrosFilters);

            let filteredPins = state.pins.filter(pin => {
                return legendaFilters.some(filter => filterPinByType(filter, pin))
            });

            if (filtrosFilters.length > 0) {
                filteredPins = filteredPins.filter(pin => filterPinsByFieldGroups(pin, filtrosFieldGroups));
            }

            if(routeManagementFilters.length) {
                filteredPins = filteredPins.filter((pin) => 
                    routeManagementFilters.some((filter) => filterPinByType(filter, pin))
                )
            }

            state.filteredPins = filteredPins
        },
        setBuildFilters: (state, action) => {

            const newFilters = action.payload;
            let activeFilters = [...state.filters];

            // add os novos filtros aos filtros ativos, mantendo todos os valores do mesmo campo
            newFilters.forEach(newFilter => {

                if (newFilter.field === 'deadline_date') {
                    // Trativa para sobrepor um valor de  'deadline_date'
                    activeFilters = activeFilters.filter(filter => filter.field !== 'deadline_date');
                }
                if (newFilter.field === 'customer_deadline_date') {
                    // Trativa para sobrepor um valor de 'customer_deadline_date'
                    activeFilters = activeFilters.filter(filter => filter.field !== 'customer_deadline_date');
                }

                const existingFilterIndex = activeFilters.findIndex(
                    filter => filter.field === newFilter.field && filter.value === newFilter.value
                );
                if (existingFilterIndex === -1) {
                    activeFilters.push(newFilter);
                }
            });

            // remove os filtros que foram desmarcados
            activeFilters = activeFilters.filter(activeFilter => {
                return newFilters.some(
                    newFilter => newFilter.field === activeFilter.field && newFilter.value === activeFilter.value
                );
            });

            state.filters = activeFilters
        },
        clearFilter: (state, action) => {
            const typeDrawer = action.payload
            let filtersCleared = state.filters.filter((value) => value.drawer !== typeDrawer)
            state.filters = filtersCleared
        },
        clearFilterDate: (state, action) => {
            const fieldToClear = action.payload;
            let filtersCleared = state.filters.filter((filter) => filter.field !== fieldToClear);
            state.filters = filtersCleared;
        },
        clearStatus: (state) => {
            state.pinsStatus = 'idle';
            state.zoom = 10
            state.mapWorkflow = 'infoDrawer'
            state.openDrawer = false
            state.filteredPins = []
            state.pins = []
        },
        setOpenDrawer: (state, action) => {
            state.openDrawer = action.payload;
        },
        setMapWorkflow: (state, action) => {
            state.mapWorkflow = action.payload;
        },
        setLeftOpenDrawer: (state, action) => {
            state.leftOpenDrawer = action.payload;
        },
        clearHunterPin: (state) => {
            state.filters = state.filters.filter(filter => 
                filter.value !== "Hunter STONE" &&
                filter.value !== "Hunter STONE (Com ineficiência)" &&
                filter.value !== "Hunter TON" &&
                filter.value !== "Hunter TON (Com ineficiência)"
            );
        },
        setServiceDetails: (state, action) => {
            state.serviceDetails = action.payload;
        },
        clearServiceDetails: (state) => {
            state.serviceDetails = {};
            state.serviceDetailsStatus = 'idle'
        },
        manuallyAlocatePin: (state, action) => {      
            const { id, angel } = action.payload;
            const colorsTaken = state.pins.map((item) => item.stylePin.color)     
            
            const angelStylePin = state.pins.find((item) => item.angel === angel)?.stylePin 
                || { color: senninhaColors.filter((color) => !colorsTaken.includes(color))[0], name: "avatar" }

            state.pins = current(state.pins).map((item) => item.id === id ? 
                { ...item, angel, stylePin: angelStylePin } 
                : item
            );
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPins.pending, (state) => {
                state.pinsStatus = 'pending';
            })
            .addCase(getPins.fulfilled, (state, action) => {

                const findMaxVolumeCoordinates = (pins) => {
                    if (pins.length === 0) return { lat: 0, lng: 0 };
                    let maxVolumePin = pins[0];
                    pins.forEach(pin => {
                        if (pin.volume_oss > maxVolumePin.volume_oss) {
                            maxVolumePin = pin;
                        }
                    });

                    return { lat: maxVolumePin.lat, lng: maxVolumePin.lng };
                };
                state.pinZeroCoordenadas = findMaxVolumeCoordinates(action.payload)
                state.center = findMaxVolumeCoordinates(action.payload)
                state.zoom = 10
                state.pins = action.payload;
                state.filteredPins = action.payload;
                state.pinsStatus = 'fulfilled';
            })
            .addCase(getPins.rejected, (state) => {
                // state.error = action.payload;
                state.pinsStatus = 'rejected';
            })
            .addCase(getServiceDetails.pending, (state) => {
                state.serviceDetailsStatus = 'pending';
            })
            .addCase(getServiceDetails.fulfilled, (state, action) => {
                state.serviceDetails = action.payload;
                state.serviceDetailsStatus = 'fulfilled';
            })
            .addCase(getServiceDetails.rejected, (state) => {
                // state.error = action.payload;
                state.serviceDetailsStatus = 'rejected';
            })
            .addCase(getAllPinsData.pending, (state) => {
                state.pinsStatus = 'pending';
                state.hunterTonStatus = 'pending';
                state.hunterStoneStatus = 'pending';
            })
            .addCase(getAllPinsData.fulfilled, (state, action) => {
                const { pins, hunterTon, hunterStone } = action.payload;
                const latPinZeroCoordenadas = () => state.pinZeroCoordenadas.lat + (Math.random() - 0.5) / 10000;
                const lngPinZeroCoordenadas = () => state.pinZeroCoordenadas.lng + (Math.random() - 0.5) / 10000;
                const filteredPins = [...pins, ...hunterTon, ...hunterStone].filter(pin =>
                    pin.angel !== "Hunter STONE" &&
                    pin.angel !== "Hunter STONE (Com ineficiência)" &&
                    pin.angel !== "Hunter TON" &&
                    pin.angel !== "Hunter TON (Com ineficiência)"
                ).map(pin => {
                    // Verifica se lat e lng são coordenadas zeradas, se sim altera para que ela vá para o centro do polo
                    if (pin.lat === 0 && pin.lng === 0) {
                        return { ...pin, lat: latPinZeroCoordenadas(), lng: lngPinZeroCoordenadas()};
                    }
                    return pin;
                });
            
                state.pins = [...pins, ...hunterTon, ...hunterStone].map(pin => {
                    // Verifica se lat e lng são coordenadas zeradas, se sim altera para que ela vá para o centro do polo
                    if (pin.lat === 0 && pin.lng === 0) {
                        return { ...pin, lat: latPinZeroCoordenadas(), lng: lngPinZeroCoordenadas()};
                    }
                    return pin;
                });
                state.filteredPins = filteredPins;
                state.pinsStatus = pins.length > 0 ? 'fulfilled' : 'rejected';
                state.hunterTonStatus = hunterTon.length > 0 ? 'fulfilled' : 'rejected';
                state.hunterStoneStatus = hunterStone.length > 0 ? 'fulfilled' : 'rejected';
            })
            .addCase(getAllPinsData.rejected, (state) => {
                state.pinsStatus = action.payload.pins.length > 0 ? 'fulfilled' : 'rejected';
                state.hunterTonStatus = action.payload.hunter.length > 0 ? 'fulfilled' : 'rejected';
                state.hunterStoneStatus = action.payload.hunter.length > 0 ? 'fulfilled' : 'rejected';
            });
    },
});

export const {
    setCenter,
    setZoom,
    setBouncePin,
    clearStatus,
    applyFilter,
    setOpenDrawer,
    setMapWorkflow,
    setServiceDetails,
    clearServiceDetails,
    clearFilter,
    clearFilterDate,
    setBuildFilters,
    setLeftOpenDrawer,
    manuallyAlocatePin,
    clearHunterPin
} = pinsSlice.actions;

export const pinsSelector = (state) => state.pinsSlice;

export default pinsSlice.reducer;
