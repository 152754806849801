import { createGlobalStyle } from "styled-components";
import Colors from "./colors";
import "./customFont.css";

export default createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        font-family: 'SharonSans' !important;
    }
    
    body {
        font-family: 'SharonSans' !important;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        background-color: ${Colors.deepBlue};
        height: 100vh;
        max-width:100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    html, body, #root{
        height: 100%;
        scroll-behavior: smooth;
        background-color: #fff;
    }

    #root {
        background-color: #F2F4F7;
    }

    p {
        margin: 0;
        padding: 0;
    }

    tr:last-child td, tr:last-child th {
        border-bottom: 0;
    }

    ::-moz-selection, ::selection {
        color: unset;
    }

    .row-justify-between-align-end {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .row-justify-between-align-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
`;
