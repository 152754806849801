import React, { useState, useEffect } from "react";
import { Select } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";

import * as S from "./styles";


const SelectDatetime = ({ selectedMonth, setSelectedMonth, titled }) => {
    const [months, setMonths] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

    useEffect(() => {
        // Função para gerar os meses disponíveis no filtro
        const generateMonths = () => {
            const monthNames = [
                "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
            ];
            let monthsArray = [];
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth(); // Mês atual (0-11)
            const currentYear = currentDate.getFullYear(); // Ano atual

            // Adiciona os meses no formato legível (ex: Outubro 2024) e no formato para o backend (ex: 2024-10)
            for (let year = 2024; year <= currentYear; year++) {
                const startMonth = year === currentYear ? currentMonth : 11; // Se for o ano atual, vai até o mês atual
                for (let month = 0; month <= startMonth; month++) {
                    const monthLabel = `${monthNames[month]} ${year}`; // Exemplo: "Outubro 2024"
                    const backendMonthValue = `${year}-${String(month + 1).padStart(2, "0")}`; // Exemplo: "2024-10"
                    monthsArray.push({
                        label: monthLabel,
                        value: backendMonthValue,
                    });
                }
            }

            setMonths(monthsArray);

            // Configura o valor padrão como o mês atual apenas se selectedMonth não estiver definido
            if (!selectedMonth) {
                const defaultMonth = {
                    value: `${currentYear}-${String(currentMonth + 1).padStart(2, "0")}`,
                    label: `${monthNames[currentMonth]} ${currentYear}`
                };
                setSelectedMonth(defaultMonth); // Define o mês atual como o valor padrão
            }
        };

        generateMonths();
    }, [selectedMonth, setSelectedMonth]);

    const handleMonthChange = (value) => {
        const selected = months.find((month) => month.value === value);
        if (selected) {
            setSelectedMonth({
                value: selected.value,
                label: selected.label
            });
            updateDateRange(value); // Atualiza o intervalo de datas
        }
    };

    const getOptions = () =>
        months.map(({ label, value }) => ({
            label: label,
            value: value
        }));

    // Função para calcular a data de início e fim com base no mês selecionado
    const updateDateRange = (monthValue) => {
        const [year, month] = monthValue.split("-"); // Extrai ano e mês
        const firstDay = new Date(year, month - 1, 1); // Primeiro dia do mês
        const lastDay = new Date(year, month, 0); // Último dia do mês

        // Formata as datas no formato DD/MM/YYYY
        const formattedStartDate = formatDate(firstDay);
        const formattedEndDate = formatDate(lastDay);

        setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate });
    };

    // Função para formatar a data no formato DD/MM/YYYY
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Inicializa o intervalo de datas quando o componente é montado ou o mês é alterado
    useEffect(() => {
        if (selectedMonth?.value) {
            updateDateRange(selectedMonth.value); // Atualiza as datas quando o mês é selecionado
        }
    }, [selectedMonth]);

    return (
        <>
            {titled && (
                <S.SelectHeaderString>
                    <span>Histórico de Faltas</span>
                    <p>De {dateRange.startDate} à {dateRange.endDate}</p>
                </S.SelectHeaderString>
            )}
            <S.SelectDatetimeDiv>
                <Select
                    prefix={
                        <GingaIcon
                            name="calendar"
                            size={20}
                            color="rgba(118, 127, 141, 1)"
                        />
                    }
                    onChange={handleMonthChange}
                    size="small"
                    value={selectedMonth?.value || ""}
                    options={getOptions()}
                    className=".ginga-lib-neutral-select"
                />
            </S.SelectDatetimeDiv>
        </>
    );
};

export default SelectDatetime;
