import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'teamManagementFrequencyHistoric';
const GET_FREQUENCY_HISTORIC = 'teamManagementFrequencyHistoric/getFrequencyHistoric';
const GET_FREQUENCY_HISTORIC_BY_ANGEL = 'teamManagementFrequencyHistoricByAngel/getFrequencyHistoricByAngel';

const initialState = {
    frequencyHistoricData: [],
    frequencyHistoricStatus: 'idle',
    frequencyHistoricStatusError: 'idle',

    frequencyHistoricAngelData: [],
    frequencyHistoricAngelStatus: 'idle',
    frequencyHistoricAngelStatusError: 'idle'
};

export const getFrequencyHistoric = createAsyncThunk(GET_FREQUENCY_HISTORIC, async ({ distrito, polo, mes }, { rejectWithValue }) => {
    try {

        const params = new URLSearchParams();

        params.append('distrito', distrito);

        if (polo) {
            params.append('polo', polo);
        }

        if (mes) {
            params.append('mes', mes);
        }

        const res = await LogisticService.requestWithAuth(`${BASE_URL}/gestao_equipes/controle_faltas/resumo?${params.toString()}`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const getFrequencyHistoricByAngel = createAsyncThunk(GET_FREQUENCY_HISTORIC_BY_ANGEL, async ({ email, mes }, { rejectWithValue }) => {
    try {

        const params = new URLSearchParams();

        params.append('email', email);
        params.append('mes', mes); //2024-11 ano-mes

        const res = await LogisticService.requestWithAuth(`${BASE_URL}/gestao_equipes/controle_faltas/historico_colaborador?${params.toString()}`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});


export const frequencyHistoricSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        // setChocolate: (state, action) => {
        //   state.frequencyHistoricStatus = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFrequencyHistoric.pending, (state) => {
                state.frequencyHistoricStatus = 'pending';
                state.frequencyHistoricStatusError = null;
            })
            .addCase(getFrequencyHistoric.fulfilled, (state, action) => {
                state.frequencyHistoricData = action.payload;
                state.frequencyHistoricStatus = 'fulfilled';
            })
            .addCase(getFrequencyHistoric.rejected, (state, action) => {
                state.frequencyHistoricStatusError = action.payload;
                state.frequencyHistoricStatus = 'rejected';
            })

            .addCase(getFrequencyHistoricByAngel.pending, (state) => {
                state.frequencyHistoricAngelStatus = 'pending';
                state.frequencyHistoricAngelStatusError = null;
                // state.frequencyHistoricAngelData = [];
            })
            .addCase(getFrequencyHistoricByAngel.fulfilled, (state, action) => {
                state.frequencyHistoricAngelData = action.payload;
                state.frequencyHistoricAngelStatus = 'fulfilled';
            })
            .addCase(getFrequencyHistoricByAngel.rejected, (state, action) => {
                state.frequencyHistoricAngelStatusError = action.payload;
                state.frequencyHistoricAngelStatus = 'rejected';
            })
    },
});

export const { } = frequencyHistoricSlice.actions

export const frequencyHistoricSelector = (state) => state.frequencyHistoricSlice;

export default frequencyHistoricSlice.reducer;
