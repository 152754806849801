import React, { useEffect, useState } from "react";
import { Button } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import Chip from "../../../../components/Chip";
import { hubsSelector } from "../../../../features/senninha/hubsSlice";
import { useSelector, useDispatch } from "react-redux";
import { pinsSelector, setMapWorkflow, setOpenDrawer } from "../../../../features/senninha/pinsSlice";
import { setOldSenninhaWorkFlow } from "../../../../features/senninha/oldSenninhaSlice";
import { useHistory } from "react-router-dom";
import { angelsSelector, getAngels } from "../../../../features/senninha/angelsSlice";
import { getRiskAreas, riskAreasSelector } from "../../../../features/senninha/riskAreasSlice";
import { handleGo2Cockpit } from "../OldSenninha/Cockpit";

import * as S from "./styles";


const TopLine = ({
    setStartWorkflow
}) => {

    const [menuState, setMenuState] = useState(false)
    const [hasMenu, setHasMenu] = useState(false)

    const history = useHistory();

    const dispatch = useDispatch()

    const {
        selectedRegionInfos,
        selectedDistrictInfos,
        selectedHubInfos
    } = useSelector(hubsSelector);

    const {
        angels,
        status: statusAngels
    } = useSelector(angelsSelector);

    const {
        riskAreas,
        status: statusRiskAreas
    } = useSelector(riskAreasSelector);

    const { 
        pins, 
        pinsStatus, 
        hunterTonStatus, 
        hunterStoneStatus 
    } = useSelector(pinsSelector);

    const loadingCockpit = statusAngels === 'pending' 
        || statusRiskAreas === 'pending'
        || pinsStatus === 'pending' 
        || hunterTonStatus === 'pending' 
        || hunterStoneStatus === 'pending'

    useEffect(() => {
        dispatch(getAngels(selectedHubInfos.children))
        dispatch(getRiskAreas(selectedDistrictInfos.value))
    }, [])

    useEffect(() => {
        const tele = document.getElementById('teleporter');
        const rec = document.getElementById('receiver');

        function resize() {
            if (!rec) return;
            const rChildren = rec.children;
            let numW = 0;

            [...rChildren].forEach(item => {
                item.outerHTML = '';
                tele.appendChild(item);
            })

            const teleW = tele.offsetWidth;
            const tChildren = tele.children;

            [...tChildren].forEach((item, i) => {
                numW += (item.offsetWidth + (i === tChildren.length-1 ? 0 : 8))

                if (numW > teleW) {
                    item.outerHTML = ''
                    rec.appendChild(item)
                }
            });

            // Add boolean de acordo com filhos do menu
            setHasMenu(rec.children.length > 0);
            // Esconde o menu caso ele não tenha filhos
            if (rec.children.length === 0) {
                setMenuState(false)
            }
        }

        window.addEventListener('resize', resize)
        resize()

        return () => window.removeEventListener('resize', resize);
    }, []);

    return (
        <S.TopContainer >
            <Chip
                text={`${selectedRegionInfos?.children} > ${selectedDistrictInfos?.children} > ${selectedHubInfos?.children}`}
                onClick={() => setStartWorkflow(false)}
            />
            <S.RightContent>
                <S.TopContent id="teleporter">
                    <Button size="small" width="100%"
                        onClick={() => dispatch(setOldSenninhaWorkFlow('posNaMochila'))}
                    >
                        POS na Mochila
                    </Button>
                    <Button
                        size="small"
                        width="100%"
                        onClick={() => {
                            dispatch(setMapWorkflow('routesDrawer'))
                            dispatch(setOpenDrawer(true))
                        }}
                    >
                        Gestão de Rotas
                    </Button>
                    {/* <Button size="small" width="100%">
                        Adic. OS de Campanha
                    </Button> */}
                    <Button
                        size="small"
                        width="100%"
                        onClick={() => {
                            dispatch(setOldSenninhaWorkFlow('poligonos'))
                        }}
                    >
                        Polígonos
                    </Button>
                    <Button
                        size="small"
                        width="100%"
                        onClick={() => history.push({
                            pathname: `/lmp/senninha/gestao-de-equipe`
                        })}
                    >
                        Gestão de Equipe
                    </Button>
                    <Button
                        size="small"
                        width="100%"
                        onClick={() => {
                            dispatch(setOldSenninhaWorkFlow('configuracoes'));
                        }}
                    >
                        Configurações
                    </Button>
                    <Button size="small" width="100%">
                        Área de Risco - Loggi
                    </Button>
                    <Button
                        size="small"
                        width="100%"
                        onClick={() => {
                            dispatch(setOldSenninhaWorkFlow('reportDoa'));
                        }}
                    >
                        Reportar DOA
                    </Button>
                    <Button
                        size="small"
                        width="100%"
                        onClick={() => {
                            dispatch(setOldSenninhaWorkFlow('motivosAtraso'));
                        }}
                    >
                        Motivo de Atraso
                    </Button>
                    <Button size="small" width="100%"
                        onClick={() => history.push({
                            pathname:
                                `/ferramentas/operacao-terceira/cobertura-estoque?regiao=${selectedRegionInfos?.children}&distrito=${selectedDistrictInfos?.children}&polo=${selectedHubInfos?.children}`
                        })}
                    >
                        Espaço QD
                    </Button>
                    <Button
                        size="small"
                        width="100%"
                        loading={loadingCockpit}
                        onClick={() => handleGo2Cockpit(
                            pins,
                            angels,
                            riskAreas,
                            selectedHubInfos.children,
                            selectedRegionInfos.children,
                            selectedDistrictInfos.children,
                            history
                        )}
                    >
                        Cockpit Distrital
                    </Button>
                </S.TopContent>
                {hasMenu && (
                    <Button size="small" width="36px" height="36px" onClick={() => setMenuState(!menuState)}>
                        {menuState === true ? <GingaIcon name="close" color="#303742" /> : <GingaIcon name="menu-more-vertical" color="#303742" />}
                    </Button>
                )}
                <S.HiddenMenu menuState={menuState} id="receiver" />
            </S.RightContent>
        </S.TopContainer>
    );
}

export default TopLine;
