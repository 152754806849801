import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';

import LogisticService from '@LogisticService';

const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'hubsSenninha';
const GET_REGIOES = 'hubsSenninha/getRegions';
const GET_DISTRITOS = 'hubsSenninha/getDistricts';
const GET_POLOS = 'hubsSenninha/getHubs';


const initialState = {
    regions: [],
    regionsStatus: 'idle',
    selectedRegionInfos: {},

    districts: [],
    districtsStatus: 'idle',
    selectedDistrictInfos: {},

    hubs: [],
    hubsStatus: 'idle',
    selectedHubInfos: {}
};

export const getRegions = createAsyncThunk(GET_REGIOES, async (_, { rejectWithValue }) => {
    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/gestao_distrito_polo/regioes`);
        return res.data;
    } catch (err) {
        if (err?.response?.status == 401) {
            window.location.href = "/login";
          }
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});

export const getDistricts = createAsyncThunk(GET_DISTRITOS, async ({ id_regiao }, { rejectWithValue }) => {
    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/gestao_distrito_polo/distritos/${id_regiao}`);
        return res.data;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});

export const getHubs = createAsyncThunk(GET_POLOS, async ({ id_distrito }, { rejectWithValue }) => {
    try {
        const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/gestao_distrito_polo/polos_por_distrito/${id_distrito}`);
        return res.data;
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data || err.response);
    }
});


export const hubsSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        setSelectedRegion: (state, action) => {
            state.selectedRegionInfos = { ...action.payload.selectedRegionInfos };
        },
        setSelectedDistrict: (state, action) => {
            state.selectedDistrictInfos = { ...action.payload.selectedDistrictInfos };
        },
        setSelectedHub: (state, action) => {
            state.selectedHubInfos = { ...action.payload.selectedHubInfos };
        },
        resetDistrict: (state) => {
            state.districts = null;
            state.districtsStatus = 'idle'
            state.selectedDistrictInfos = null
        },
        resetHub: (state) => {
            state.hubs = null
            state.hubsStatus = 'idle'
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRegions.pending, (state) => {
                state.regionsStatus = 'pending';
            })
            .addCase(getRegions.fulfilled, (state, action) => {
                state.regions = action.payload;
                state.regionsStatus = 'fulfilled';
            })
            .addCase(getRegions.rejected, (state) => {
                // state.error = action.payload;
                state.regionsStatus = 'rejected';
            })
            .addCase(getDistricts.pending, (state) => {
                state.districtsStatus = 'pending';
            })
            .addCase(getDistricts.fulfilled, (state, action) => {
                state.districts = action.payload;
                state.districtsStatus = 'fulfilled';
            })
            .addCase(getDistricts.rejected, (state) => {
                // state.error = action.payload;
                state.districtsStatus = 'rejected';
            })
            .addCase(getHubs.pending, (state) => {
                state.hubsStatus = 'pending';
            })
            .addCase(getHubs.fulfilled, (state, action) => {
                state.hubs = action.payload;
                state.hubsStatus = 'fulfilled';
            })
            .addCase(getHubs.rejected, (state) => {
                // state.error = action.payload;
                state.hubsStatus = 'rejected';
            })
    },
});

export const {
    setSelectedRegion, setSelectedDistrict, setSelectedHub,
    resetDistrict, resetHub,
} = hubsSlice.actions;
export const hubsSelector = (state) => state.hubsSlice;

export default hubsSlice.reducer;
