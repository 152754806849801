import React, { useEffect, useState } from "react";
import { Alert, Button, Checkbox, Dialog, Drawer, Input, Tag } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTrackingDeliveries, trackingDeliveriesSelector } from "../../../../../../../features/senninha/trackingDeliveriesSlice";
import machineError from "@assets/imgs/machine-error.svg";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";
import { getTickets, resetTicketSlice, ticketsSelector } from "../../../../../../../features/senninha/ticketSlice";
import { hubsSelector } from "../../../../../../../features/senninha/hubsSlice";
import filesGrey from "@assets/imgs/files-grey.svg";
import warning from "@assets/icons/Warning.svg";
import { oplFromTo } from "../../../../Map/components/Drawers/util";

import * as S from "./styles";


const ProgressContent = ({ onOpenDeliveryDrawer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [ticketErrorModal, setTicketErrorModal] = useState(false)
    const [filters, setFilters] = useState()
    const [activeFilters, setActiveFilters] = useState({
        opl: null,
        batch: null,
        searchBar: null,
    })
    const [searchText, setSearchText] = useState('')
    const [filteredDeliveries, setFilteredDeliveries] = useState([])
    const [checkedList, setCheckedList] = useState([])
    const [checkboxOptions, setCheckboxOptions] = useState([])

    const dispatch = useDispatch();

    const { 
        deliveries, 
        statusGetDeliveries, 
        errorGetDeliveries
    } = useSelector(trackingDeliveriesSelector)

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const { status: statusTickets, error: errorTickets } = useSelector(ticketsSelector)

    const loading = statusGetDeliveries === 'pending'
    const checkAll = checkboxOptions.length === checkedList.length && checkedList.length
    const indeterminate = checkedList.length > 0 && checkedList.length < checkboxOptions.length

    const togglePopover = () => setIsOpen((p) => !p);

    const getAllFilters = () => {
        const opls = []
        const batches = []
        deliveries.forEach(({ operador_logistico, lote, num_pedido }) => {
            if (!opls.find((item) => item === operador_logistico))
                opls.push(operador_logistico)
            if (num_pedido && !batches.includes(num_pedido)) {
                batches.push(num_pedido)
            } 
        })
        return { opls, batches }
    }

    const clearFilters = () => {
        setActiveFilters({
            opl: null,
            batch: null,
            searchBar: null,
        });
        applyFilters();
    }

    const applyFilters = () => {
        const res = deliveries?.filter(({ lote, operador_logistico, id_atendimento, oss, num_pedido }) => 
            (activeFilters.opl?.length ? activeFilters.opl.includes(operador_logistico) : true)
            && (activeFilters.batch?.length ? activeFilters.batch?.includes(num_pedido) : true )
            && (activeFilters.searchBar ? 
                ((id_atendimento.includes(activeFilters.searchBar))
                || (oss.find(({ id_os }) => id_os.toString().includes(activeFilters.searchBar))))
                : true)
        )
        if (res) setFilteredDeliveries(res)
        return res
    }

    const onCheckAllChange = (e) =>
        setCheckedList(e.target.checked ? checkboxOptions : [])   

    const handleChangeCheckbox = (checkedValue) =>
        setCheckedList((p) => checkedList.find((item) => item === checkedValue) ? 
            p.filter((item) => item !== checkedValue)
            : [...p, checkedValue]
        )

    const handlePrint = () => {
        const deliveries2BePrinted = deliveries.filter(({ reference_key }) => checkedList.includes(reference_key))
        const payload = deliveries2BePrinted.map(({ id_atendimento, reference_key }) => ({
            id_atendimento,
            reference_key
        }))

        dispatch(getTickets(payload))
    }

    const content = () => (
        filters &&
            <>
                <S.HeaderPopover>
                    <span>Filtrar por</span>
                    <GingaIcon name='close' color="rgba(48, 55, 66, 1)" size={24} onClick={togglePopover} />
                </S.HeaderPopover>
                <S.SubTitlesPopover>
                    <p>Operador logístico</p>
                    <S.Tags>
                        {filters?.opls?.map((item) => (
                            <S.TagCustom
                                onClick={() => setActiveFilters(
                                    (p) => ({ 
                                        ...p, 
                                        opl: activeFilters.opl?.includes(item) ? 
                                            activeFilters.opl?.filter(((item2) => item2 !== item)) 
                                            : [...(p.opl || []), item] 
                                    })
                                )}
                                isActive={activeFilters.opl?.includes(item)}
                            >
                                {capitalizeAllFirstLetters(oplFromTo(item))}
                            </S.TagCustom>
                        ))}
                    </S.Tags>
                </S.SubTitlesPopover>
                <S.SubTitlesPopover>
                    <p>Lote</p>
                    <S.Tags>
                        {filters.batches.map((item) => (
                            <S.TagCustom
                                onClick={() => setActiveFilters(
                                    (p) => ({ 
                                        ...p, 
                                        batch: activeFilters.batch?.includes(item) ? 
                                        activeFilters.batch?.filter((item2) => item2 !== item) 
                                        : [...(p.batch || []), item] 
                                    })
                                )}
                                isActive={activeFilters.batch?.includes(item)}
                            >
                                {item}
                            </S.TagCustom>
                        ))}
                    </S.Tags>
                </S.SubTitlesPopover>
                <S.FooterButton>
                    {activeFilters.opl?.length || activeFilters.batch?.length ? (
                        <Button
                            onClick={() => {
                                togglePopover()
                                clearFilters();
                            }}
                            width="100%"
                        >
                            Limpar filtros
                        </Button>
                    ) : <></>}

                    <Button
                        type="primary"
                        onClick={() => {
                            togglePopover()
                            const res = applyFilters()
                            if ((activeFilters.opl?.length || activeFilters.batch?.length) && res?.length) {
                                const allSimulationsIds = res.map((item) => item.reference_key)
                                setCheckedList(checkedList.filter((item) => allSimulationsIds.includes(item)))
                            }
                        }}
                        width="100%"
                    >
                        Filtrar
                    </Button>
                </S.FooterButton>
            </>
    );

    const handleCloseErroTicketModal = () => {
        setTicketErrorModal(false);
        dispatch(resetTicketSlice());
    }

    useEffect(() => {
        if (deliveries) setFilters(getAllFilters())
    }, [deliveries])

    useEffect(() => {
        if (!isOpen && !activeFilters.opl && !activeFilters.batch)
            setFilteredDeliveries(deliveries)            
    }, [isOpen, deliveries])

    useEffect(() => {
        if (!searchText) {
            setActiveFilters((p) => ({ ...p, searchBar: null }))
        }
    }, [searchText])

    useEffect(() => {
        if (filteredDeliveries?.length) 
            setCheckboxOptions(filteredDeliveries.map(({ reference_key }) => reference_key))
    }, [filteredDeliveries])

    useEffect(() => {
        const res = applyFilters()
        if (activeFilters.searchBar && res?.length) {
            const allSimulationsIds = res.map((item) => item.reference_key)
            setCheckedList(checkedList.filter((item) => allSimulationsIds.includes(item)))
        }
    }, [activeFilters.searchBar])

    useEffect(() => {
        if (statusTickets === 'fulfilled') {
            setCheckedList([])
            dispatch(resetTicketSlice())
        }

        if (statusTickets === 'rejected') {
            setTicketErrorModal(true)
            dispatch(resetTicketSlice())
        }
    }, [statusTickets])

    useEffect(() => {
        setCheckedList((p) => p.filter((item) => deliveries?.find((item2) => item2.reference_key === item)))
    }, [deliveries])

    useEffect(() => {
        return () => {
            dispatch(resetTicketSlice());
        }
    }, [])

    return (
        <>
            <Dialog.Root
                open={ticketErrorModal}
                onCancel={handleCloseErroTicketModal}
            >
                <Dialog.Header title="Imprimir etiqueta" />

                <Dialog.ContentHeader
                ilustration={<img src={warning} />}
                description={`Ocorreu um erro ao tentar gerar a etiqueta. Motivo: ${errorTickets}`}
                />

                <Dialog.Footer>
                <Button onClick={handleCloseErroTicketModal}>Cancelar</Button>
                <Button
                    type="primary"
                    onClick={() => {
                        setTicketErrorModal(false)
                        handlePrint()
                    }}
                >
                    Tentar novamnete
                </Button>
                </Dialog.Footer>
            </Dialog.Root>

            <Drawer.Body>
                {loading ? 
                    <Drawer.Loading
                        message="Carregando deliveries..."
                    />
                    :
                    errorGetDeliveries ?
                        <S.EmptyDrawer>
                            <img src={machineError} height={64} width={74} />
                            <p>
                                Houve um erro!
                            </p>
                            <Button
                                height="44px"
                                onClick={() => dispatch(getTrackingDeliveries(selectedHubInfos.children))}
                            >
                                <GingaIcon
                                    name="action-refresh"
                                    color="#fffff"
                                    size={18}
                                />
                                Atualizar lista
                            </Button>
                        </S.EmptyDrawer>
                        :
                        deliveries?.length ?
                            <>
                                <S.AllDeliveryDrawerContent>
                                    <S.HeaderDelivery>
                                        <Input
                                            label='Pesquisar'
                                            placeholder='Stonecode ou Nº da OS'
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onPressEnter={(e) => {
                                                setActiveFilters((p) => ({ ...p, searchBar: e.target.value }))
                                            }}
                                            size="small"
                                            suffixIcon={<GingaIcon name='search' color='#767F8D' size='18px' />}
                                            allowClear
                                        />
                                        <Popover
                                            open={isOpen}
                                            placement="bottomRight"
                                            trigger="click"
                                            content={content}
                                            overlayClassName="ginga-popover"
                                        >
                                            <Button
                                                width="44px"
                                                height="44px"
                                                onClick={togglePopover}
                                            >
                                                <GingaIcon
                                                    name="filter"
                                                    color="#fffff"
                                                />
                                            </Button>
                                        </Popover>
                                        <Button
                                            width="44px"
                                            height="44px"
                                            onClick={() => dispatch(getTrackingDeliveries(selectedHubInfos.children))}
                                        >
                                            <GingaIcon
                                                name="action-refresh"
                                                color="#fffff"
                                            />
                                        </Button>
                                    </S.HeaderDelivery>
                                    <S.AlertArea>
                                        <Alert
                                            description="Você vai precisar entrar em contato com a %b%Loggi (4020-1460)%b% para definir o ponto de retorno em casos de: %b%Ineficiência, Troca%b% ou %b%Desinstalação.%b%"
                                            type="info"
                                            className='ginga-lib-alert'
                                        />
                                    </S.AlertArea>
                                    <S.DeliveryTopContent>
                                        <Checkbox
                                            label={`Selecionar tudo`}
                                            indeterminate={indeterminate}
                                            onChange={onCheckAllChange}
                                            checked={checkAll}
                                            size={24}
                                            onClick={(e) => 
                                                e.target.blur()
                                            }
                                        />
                                    </S.DeliveryTopContent>

                                </S.AllDeliveryDrawerContent>

                                    {filteredDeliveries?.map((delivery, i) => (
                                            <Drawer.Row
                                                key={i}
                                                onClick={() => onOpenDeliveryDrawer(delivery)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Drawer.RowPrefix>
                                                    <Checkbox
                                                        size={24}
                                                        value={delivery.reference_key}
                                                        checked={checkedList.includes(delivery.reference_key)}
                                                        onChange={(e) => handleChangeCheckbox(e.target.value)}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            e.target.blur()
                                                        }}
                                                    />
                                                </Drawer.RowPrefix>
                                                <Drawer.RowText
                                                    text={capitalizeAllFirstLetters(delivery.cliente)}
                                                    description={`${
                                                        capitalizeAllFirstLetters(delivery.operador_logistico)
                                                    } | ${
                                                        delivery.oss.map(({ servico, id_os }) => 
                                                        ` ${capitalizeAllFirstLetters(servico)} (OS ${id_os})`)
                                                    } | Lote ${ delivery?.num_pedido || delivery.lote}`}
                                                />
                    
                                                <Drawer.RowActions>
                                                    <Tag
                                                        text={capitalizeAllFirstLetters(delivery.status.mensagem)}
                                                        type={delivery.status.tipo}
                                                    />
                                                </Drawer.RowActions>
                                        </Drawer.Row>
                                    ))}
                                {filteredDeliveries?.length === 0 && (
                                    <Drawer.Response
                                        element={<img src={machineError} />}
                                        message="Não encontrado"
                                    />
                                )}
                            </>
                            :
                            <S.EmptyDrawer>
                                <img src={filesGrey} />
                                <p>
                                    Lista vazia.
                                </p>
                                <Button
                                    height="44px"
                                    onClick={() => dispatch(getTrackingDeliveries(selectedHubInfos.children))}
                                >
                                    <GingaIcon
                                        name="action-refresh"
                                        color="#fffff"
                                        size={18}
                                    />
                                    Atualizar lista
                                </Button>
                            </S.EmptyDrawer>
                }
            </Drawer.Body>
            {checkedList?.length ? (
                <Drawer.Footer>
                    <Button
                        type='primary'
                        height='52px'
                        onClick={handlePrint}
                        loading={statusTickets === 'pending'}
                        disabled={!checkedList.length}
                    >
                        <GingaIcon
                            name="printer"
                            color="#fff"
                            size={16}
                        />
                        Imprimir etiqueta ({checkedList.length})
                    </Button>
                </Drawer.Footer>
            ) : <></>
            }

        </>
    )
}

export default ProgressContent;
