import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'teamManagementUnproductivityJustification';
const GET_JUSTIFICATION_UNPRODUCTIVITY = 'teamManagementUnproductivityJustification/getUnproductivityJustificationTable';
const GET_ALERT_JUSTIFICATION_UNPRODUCTIVITY = 'teamManagementUnproductivityJustification/getAlertUnproductivityJustificationTable';
const POST_JUSTIFICATION_UNPRODUCTIVITY = 'teamManagementUnproductivityJustification/postUnproductivityJustification';
const POST_ASSESS_JUSTIFICATION_UNPRODUCTIVITY = 'teamManagementUnproductivityJustification/postAssessUnproductivityJustification';

const initialState = {
    unproductivityJustificationData: null,
    unproductivityJustificationStatus: 'idle',
    unproductivityJustificationStatusError: 'idle',

    justificationData: null,
    justificationStatus: 'idle',
    justificationStatusError: 'idle',

    assessUnproductivityJustificationData: null,
    assessUnproductivityJustificationStatus: 'idle',
    assessUnproductivityJustificationStatusError: 'idle'
};

export const getUnproductivityJustificationTable = createAsyncThunk(GET_JUSTIFICATION_UNPRODUCTIVITY, async ({ distrito, polo, data_inicio, data_fim }, { rejectWithValue }) => {
    try {

        const params = new URLSearchParams();

        params.append('distrito', distrito);
        
        if (polo) {
            params.append('polo', polo);
        }

        const res = await LogisticService.requestWithAuth(`${BASE_URL}/gestao_equipes/controle_faltas/historico?${params.toString()}`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const getAlertUnproductivityJustificationTable = createAsyncThunk(GET_ALERT_JUSTIFICATION_UNPRODUCTIVITY, async ({ distrito, polo, data_inicio, data_fim }, { rejectWithValue }) => {
    try {

        const params = new URLSearchParams();

        params.append('distrito', distrito);
        
        if (polo) {
            params.append('polo', polo);
        }

        const res = await LogisticService.requestWithAuth(`${BASE_URL}/gestao_equipes/controle_faltas/pendentes?${params.toString()}`);

        return res.data;
    } catch (err) {
        return rejectWithValue(err.response?.data);
    }
});

export const postUnproductivityJustification = createAsyncThunk(POST_JUSTIFICATION_UNPRODUCTIVITY, async (data, { rejectWithValue, dispatch }) => {
    try {
        await LogisticService.postWithAuth(`${BASE_URL}/gestao_equipes/controle_faltas/pendentes`, data.payload);

        return
    } catch (err) {
        return rejectWithValue(err?.response?.data?.erro || 'error');
    }
});

export const postAssessUnproductivityJustification = createAsyncThunk(POST_ASSESS_JUSTIFICATION_UNPRODUCTIVITY, async (data, { rejectWithValue }) => {
    try {
        await LogisticService.postWithAuth(`${BASE_URL}/gestao_equipes/controle_faltas/aprovacao`, data.payload);

        return
    } catch (err) {
        return rejectWithValue(err?.response?.data?.erro || 'error');
    }
});

export const unproductivityJustificationSlice = createSlice({
    name: REDUCER_NAME,
    initialState,
    reducers: {
        setResetJustificationStatus: (state) => {
            state.justificationStatus = 'idle'
        },
        setResetAssessUnproductivityJustificationStatus: (state) => {
            state.assessUnproductivityJustificationStatus = 'idle'
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUnproductivityJustificationTable.pending, (state) => {
                state.unproductivityJustificationStatus = 'pending';
                state.unproductivityJustificationStatusError = null;
            })
            .addCase(getUnproductivityJustificationTable.fulfilled, (state, action) => {
                state.unproductivityJustificationData = action.payload;
                state.unproductivityJustificationStatus = 'fulfilled';
            })
            .addCase(getUnproductivityJustificationTable.rejected, (state, action) => {
                state.unproductivityJustificationStatusError = action.payload;
                state.unproductivityJustificationStatus = 'rejected';
            })

            .addCase(getAlertUnproductivityJustificationTable.pending, (state) => {
                state.unproductivityJustificationStatus = 'pending';
                state.unproductivityJustificationStatusError = null;
            })
            .addCase(getAlertUnproductivityJustificationTable.fulfilled, (state, action) => {
                state.unproductivityJustificationData = action.payload;
                state.unproductivityJustificationStatus = 'fulfilled';
            })
            .addCase(getAlertUnproductivityJustificationTable.rejected, (state, action) => {
                state.unproductivityJustificationStatusError = action.payload;
                state.unproductivityJustificationStatus = 'rejected';
            })

            .addCase(postUnproductivityJustification.pending, (state) => {
                state.justificationStatus = 'pending';
                state.justificationStatusError = null;
            })
            .addCase(postUnproductivityJustification.fulfilled, (state, action) => {
                state.justificationData = action.payload;
                state.justificationStatus = 'fulfilled';
            })
            .addCase(postUnproductivityJustification.rejected, (state, action) => {
                state.justificationStatusError = action.payload;
                state.justificationStatus = 'rejected';
            })

            .addCase(postAssessUnproductivityJustification.pending, (state) => {
                state.assessUnproductivityJustificationStatus = 'pending';
                state.assessUnproductivityJustificationStatusError = null;
            })
            .addCase(postAssessUnproductivityJustification.fulfilled, (state, action) => {
                state.assessUnproductivityJustificationData = action.payload;
                state.assessUnproductivityJustificationStatus = 'fulfilled';
            })
            .addCase(postAssessUnproductivityJustification.rejected, (state, action) => {
                state.assessUnproductivityJustificationStatusError = action.payload;
                state.assessUnproductivityJustificationStatus = 'rejected';
            })
    },
});

export const { setResetJustificationStatus, setResetAssessUnproductivityJustificationStatus } = unproductivityJustificationSlice.actions

export const unproductivityJustificationSelector = (state) => state.unproductivityJustificationSlice;

export default unproductivityJustificationSlice.reducer;
