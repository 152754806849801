import React, { useEffect } from "react";
import {
  Button,
  Card,
  Select,
  Table,
  Tag,
} from "@stonelog/stonelog-design-system";
import { TagCustom } from "@components/TagCustom";
import { usePaginationConfig } from "@hooks/usePaginationConfig";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { DatePickerCustom } from "@components/DatePickerCustom";
import { compareTableColumn, toCapitalize } from "@utils/common";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnproductivityJustificationTable,
  postAssessUnproductivityJustification,
  postUnproductivityJustification,
  getAlertUnproductivityJustificationTable,
  setResetJustificationStatus,
  unproductivityJustificationSelector,
  setResetAssessUnproductivityJustificationStatus,
} from "../../../../../../../features/senninha/GestaoEquipe/unproductivityJustificationSlice";
import {
  getHubs,
  hubsSelector,
} from "../../../../../../../features/senninha/hubsSlice";
import moment from "moment";
import "moment/locale/pt-br";
import { useAuthContext } from "@stonelog/stonelog-auth-components";
import { formatDateTime } from "../../../../../../../utils/formatter";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";
import { Tooltip } from "antd";

import * as S from "./styles";

const UnproductiveTable = ({
  senninhaScreen, //props passada apenas quando é o alerta/trava da tela do senninha
}) => {
  const [unproductiveDate, setUnproductiveDate] = useState("");
  const [selectedRows, setSelectedRows] = useState("");
  const [tags, setTags] = useState([]);
  const [activeTags, setActiveTags] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [optionsJustify, _] = useState([
    {
      value: "Compensação de feriado local",
      label: "Compensação de feriado local",
    },
    {
      value: "Deslocamento viagem",
      label: "Deslocamento viagem",
    },
    {
      value: "Eventos internos",
      label: "Eventos internos",
    },
    {
      value: "Envio de delivery",
      label: "Envio de delivery",
    },
    {
      value: "Outros trabalhos interno",
      label: "Outros trabalhos interno",
    },
    {
      value: "Rota sombra especialista",
      label: "Rota sombra especialista",
    },
    {
      value: "Onboarding",
      label: "Onboarding",
    },
    {
      value: "Escala de sábado",
      label: "Escala de sábado",
    },
    {
      value: "Folga combinada",
      label: "Folga combinada",
    },
    {
      value: "Atestado médico",
      label: "Atestado médico",
    },
    {
      value: "Falta não justificada",
      label: "Falta não justificada",
    },
  ]);

  const regionalEmails = [
    "eduardo.torres@stone.com.br",
    "mariana.brito@stone.com.br",
    "boneve@stone.com.br",
    "alyson.ferreira@stone.com.br",
    "rair.suzuki@stone.com.br",
    "amauri.ferreira@stone.com.br",
    "douglas.sardinha@stone.com.br",
    "gcalves@stone.com.br",
    "levedove@stone.com.br",
    "lucas.rodrigues@stone.com.br",
    "ncabral@stone.com.br",
  ];

  const paginationConfig = usePaginationConfig([5, 10]);

  const userInfos = useAuthContext().getUser();

  moment.locale("pt-br");

  const dispatch = useDispatch();

  const {
    unproductivityJustificationData,
    unproductivityJustificationStatus,
    justificationStatus,
    assessUnproductivityJustificationStatus,
  } = useSelector(unproductivityJustificationSelector);

  const {
    selectedHubInfos,
    selectedDistrictInfos,
    districts,
    hubs,
  } = useSelector(hubsSelector);

  const [hub, setHub] = useState(selectedHubInfos?.children);
  const [district, setDistrict] = useState(selectedDistrictInfos?.children);

  useEffect(() => {
    if (districts.length === 0) {
      window.location.href = "/senninha";
    }
  }, []);

  useEffect(() => {
    if (!senninhaScreen) {
      // tela de gestao de equipe toda
      dispatch(
        getUnproductivityJustificationTable({
          polo: hub,
          distrito: district,
        })
      );
    } else {
      dispatch(
        getAlertUnproductivityJustificationTable({
          polo: hub,
          distrito: district,
        })
      );
    }
    dispatch(setResetJustificationStatus());
    dispatch(setResetAssessUnproductivityJustificationStatus());
  }, []);

  // Parece a mesma coisa, mas aqui estamos alterando quando há uma justificativa sendo preenchida. na hr de justificar...
  useEffect(() => {
    if (
      assessUnproductivityJustificationStatus !== "idle" ||
      justificationStatus !== "idle"
    ) {
      if (!senninhaScreen) {
        if (
          justificationStatus === "fulfilled" ||
          assessUnproductivityJustificationStatus === "fulfilled"
        ) {
          dispatch(
            getUnproductivityJustificationTable({
              polo: hub,
              distrito: selectedDistrictInfos?.children,
            })
          );
        }
      } else {
        if (
          justificationStatus === "fulfilled" ||
          assessUnproductivityJustificationStatus === "fulfilled"
        ) {
          dispatch(
            getAlertUnproductivityJustificationTable({
              polo: hub,
              distrito: selectedDistrictInfos?.children,
            })
          );
        }
      }
    }
  }, [justificationStatus, assessUnproductivityJustificationStatus]);

  const handleAssessUnproductivityJustification = (assess) => {
    const payload = selectedRows?.map((item) => ({
      id_justificativa: item.id_justificativa,
      aprovado: assess,
      email: userInfos?.email,
      // justificativa_reprovado: `${item.justificativa} reprovado.`
      ...(assess === false && {
        justificativa_reprovado: `${item.justificativa} reprovado.`,
      }),
    }));

    payload.forEach((singlePayload) => {
      dispatch(
        postAssessUnproductivityJustification({ payload: singlePayload })
      );
    });
  };

  const handleJustification = (value, record) => {
    const payload = {
      id_controle: record.id_controle,
      justificativa: value,
      email: userInfos?.email,
    };

    dispatch(
      postUnproductivityJustification({
        payload,
      })
    );
  };

  const filterData = () => {
    return unproductivityJustificationData?.filter((item) => {
      // Filtro por colaborador (nome)
      if (selectedCollaborator && item.nome !== selectedCollaborator) {
        return false;
      }

      // Filtro por data de improdutividade
      if (unproductiveDate) {
        if (
          !moment(item.data_falta).isSame(
            moment(unproductiveDate, "DD/MM/YYYY"),
            "day"
          )
        ) {
          return false;
        }
      }

      // Filtro por situação (tags)
      if (activeTags.length > 0) {
        const tagKeys = activeTags.map((tag) => tag.key);
        const tagValue = senninhaScreen
          ? item.data_falta
          : item.status?.mensagem;

        if (!tagKeys.includes(tagValue)) {
          return false;
        }
      }

      return true;
    });
  };

  useEffect(() => {
    const tagCounts = unproductivityJustificationData?.reduce((acc, item) => {
      const key = senninhaScreen
        ? item.data_falta // senninhaScreen é true, usa data_falta
        : item.status?.mensagem; // do contrario usa status?.mensagem

      if (key) {
        acc[key] = (acc[key] || 0) + 1;
      }
      return acc;
    }, {});
    if (tagCounts) {
      const newTags = Object.entries(tagCounts).map(([key, count]) => ({
        value: `${key} (${count})`,
        key,
      }));
      setTags(newTags);
    }
  }, [unproductivityJustificationData, senninhaScreen]);

  const showColumnsIfReproved = unproductivityJustificationData?.some(
    (item) => item.status?.mensagem?.toLowerCase() === "reprovado"
  );

  const resetFilters = () => {
    setUnproductiveDate("");
    setSelectedCollaborator(null);
    setActiveTags([]);
  };

  const getDistrictsOptions = () =>
    districts.map(({ name, id }) => ({
      value: name,
      label: name,
      children: name,
      id: id,
    }));

  const getHubsOptions = () =>
    hubs.map(({ name, id }) => ({
      value: name,
      label: name,
      children: name,
      id: id,
    }));

  const onChangeDistrict = (value, component) => {
    dispatch(
      getHubs({
        id_distrito: component.id,
      })
    );
    setDistrict(value);
    setHub(null);
    if (!senninhaScreen) {
      // tela de gestao de equipe toda
      dispatch(
        getUnproductivityJustificationTable({
          distrito: value,
        })
      );
    } else {
      dispatch(
        getAlertUnproductivityJustificationTable({
          distrito: value,
        })
      );
    }
  };

  const onChangeHub = (value) => {
    setHub(value);

    if (!senninhaScreen) {
      // tela de gestao de equipe toda
      dispatch(
        getUnproductivityJustificationTable({
          polo: value,
          distrito: district,
        })
      );
    } else {
      dispatch(
        getAlertUnproductivityJustificationTable({
          polo: value,
          distrito: district,
        })
      );
    }
  };

  const dataSource = filterData()?.map((item, idx) => ({
    key: idx,
    nome: item.nome,
    distrito: item.distrito,
    polo: item.polo_origem,
    improdutivo_em: item.data_falta,
    justificativa: item.justificativa,
    justificado_por: item.justificado_por,
    justificado_em: item.data_justificativa,
    situacao_tipo: item.status?.tipo,
    situacao_mensagem: item.status?.mensagem,
    avaliado_por: item.aprovado_por,
    avaliado_em: item.data_aprovacao,
    id_controle: item.id,
    id_justificativa: item.id_justificativa,
    justificativa_reprovado: item.justificativa_reprovado,
    email: item.email,
  }));

  const columns = [
    {
      title: "Colaborador",
      dataIndex: "nome",
      sorter: {
        compare: (a, b) => compareTableColumn(a, b, "nome"),
      },
      key: "nome",
      render: (value, record) => {
        return value ? (
          <S.StringDesign>
            <span>{capitalizeAllFirstLetters(value)}</span>
            <p>{record.email}</p>
          </S.StringDesign>
        ) : (
          <div>-</div>
        );
      },
    },
    {
      title: "Distrito",
      dataIndex: "distrito",
      key: "distrito",
    },
    {
      title: "Polo",
      dataIndex: "polo",
      key: "polo",
    },
    {
      title: "Improdutivo em",
      dataIndex: "improdutivo_em",
      sorter: {
        compare: (a, b) => compareTableColumn(a, b, "improdutivo_em"),
      },
      key: "improdutivo_em",
      render: (value) => formatDateTime(value),
    },
    {
      title: "Justificativa",
      dataIndex: "justificativa",
      key: "justificativa",
      render: (_, record) => (
        <Select
          size="small"
          label="Justificativa"
          placeholder="Selecione uma opção"
          className="ginga-lib-select-table"
          options={optionsJustify}
          value={record.justificativa}
          allowClear
          onChange={(value) => handleJustification(value, record)}
          loading={justificationStatus === "pending"}
        />
      ),
    },
    {
      title: () => {
        return (
          <S.UnproductiveFilterColumn>
            <span>justificado por</span>
            <Tooltip
              title="Pessoa que informou o motivo da justificativa."
              placement="top"
              overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top-right"
            >
              <GingaIcon
                name="round-info"
                color="rgba(118, 127, 141, 1)"
                size="16px"
                cursor={"pointer"}
              />
            </Tooltip>
          </S.UnproductiveFilterColumn>
        );
      },
      dataIndex: "justificado_por",
      key: "justificado_por ",
      render: (value, record) => {
        return value ? (
          <S.StringDesign>
            <span>{value}</span>
            <p>Justificado em {formatDateTime(record?.justificado_em)}</p>
          </S.StringDesign>
        ) : (
          <div>-</div>
        );
      },
    },
    ...(senninhaScreen !== true
      ? [
          {
            title: () => {
              return (
                <S.UnproductiveFilterColumn>
                  <span>Situação</span>
                  <Tooltip
                    title="Informa se as justificativas foram aprovadas por um Regional ou ainda estão pendentes."
                    placement="top"
                    overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top-right"
                  >
                    <GingaIcon
                      name="round-info"
                      color="rgba(118, 127, 141, 1)"
                      size="16px"
                      cursor={"pointer"}
                    />
                  </Tooltip>
                </S.UnproductiveFilterColumn>
              );
            },
            dataIndex: "situacao_mensagem",
            sorter: {
              compare: (a, b) => compareTableColumn(a, b, "situacao_mensagem"),
            },
            key: "situacao_mensagem",
            render: (_, record) => (
              <Tag
                type={record?.situacao_tipo}
                text={record?.situacao_mensagem}
              />
            ),
          },
        ]
      : []),
    ...(showColumnsIfReproved
      ? [
          {
            title: () => {
              return (
                <S.UnproductiveFilterColumn>
                  <span>Avaliado por</span>
                  <Tooltip
                    title="Regional que avaliou a justificativa."
                    placement="top"
                    overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top-right"
                  >
                    <GingaIcon
                      name="round-info"
                      color="rgba(118, 127, 141, 1)"
                      size="16px"
                      cursor={"pointer"}
                    />
                  </Tooltip>
                </S.UnproductiveFilterColumn>
              );
            },
            dataIndex: "avaliado_por",
            key: "avaliado_por",
            render: (value, record) => {
              return value ? (
                <S.StringDesign>
                  <span>{value}</span>
                  <p>Avaliado em {formatDateTime(record?.avaliado_em)}</p>
                </S.StringDesign>
              ) : (
                <div>-</div>
              );
            },
          },
          {
            title: () => {
              return (
                <S.UnproductiveFilterColumn>
                  <span>Motivo</span>
                  <Tooltip
                    title="Motivo que o regional aprovou ou reprovou a justificativa."
                    placement="top"
                    overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top-right"
                  >
                    <GingaIcon
                      name="round-info"
                      color="rgba(118, 127, 141, 1)"
                      size="16px"
                      cursor={"pointer"}
                    />
                  </Tooltip>
                </S.UnproductiveFilterColumn>
              );
            },
            dataIndex: "justificativa_reprovado",
            key: "justificativa_reprovado",
            render: (value, record) => {
              return value ? (
                <S.StringDesign>
                  <span>{value}</span>
                  <p>
                    Justificado por {record.justificado_por}, em{" "}
                    {formatDateTime(record?.justificado_em)}
                  </p>
                </S.StringDesign>
              ) : (
                <div>-</div>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <Card.Root>
      <Card.Header>
        <Card.HeaderText
          title={`Colaboradores Improdutivos (${
            unproductivityJustificationData?.length
          }) - ${toCapitalize(moment().format("MMMM [de] YYYY"))}`}
          subtitle={`Justifique a improdutividade de cada colaborador e aprove ou reprove justificativas pendentes nesta tabela.`}
        />
        {regionalEmails?.includes(userInfos?.email) && !senninhaScreen && (
          <Card.HeaderActions>
            <Button
              size="small"
              type="negative"
              disabled={!selectedRows.length}
              onClick={() => handleAssessUnproductivityJustification(false)}
              loading={assessUnproductivityJustificationStatus === "pending"}
            >
              {`Reprovar (${selectedRows?.length})`}
            </Button>
            <Button
              size="small"
              type="primary"
              disabled={!selectedRows.length}
              onClick={() => handleAssessUnproductivityJustification(true)}
              loading={assessUnproductivityJustificationStatus === "pending"}
            >
              {`Aprovar (${selectedRows?.length})`}
            </Button>
          </Card.HeaderActions>
        )}
      </Card.Header>
      <S.UnproductiveFilterContainer>
        <S.LeftUnproductiveFilter>
          <S.LeftUnproductiveSelectFilter>
            <Select
              prefixIcon={
                <GingaIcon
                  name="location"
                  color="rgba(118, 127, 141, 1)"
                  size={21}
                />
              }
              onChange={onChangeDistrict}
              options={getDistrictsOptions()}
              value={district}
              placeholder="Selecione uma opção"
              label="Distrito"
              size="small"
              disabled={senninhaScreen}
            />
            <Select
              prefixIcon={
                <GingaIcon
                  name="location"
                  color="rgba(118, 127, 141, 1)"
                  size={21}
                />
              }
              onChange={onChangeHub}
              options={getHubsOptions()}
              value={hub}
              placeholder="Selecione uma opção"
              label="Polo"
              size="small"
              allowClear
              disabled={senninhaScreen}
            />
            <Select
              prefixIcon={
                <GingaIcon
                  name="avatar"
                  color="rgba(118, 127, 141, 1)"
                  size={21}
                />
              }
              options={unproductivityJustificationData?.reduce((acc, item) => {
                if (!acc.map((option) => option.value).includes(item.nome)) {
                  acc.push({ value: item.nome, label: item.nome });
                }
                return acc;
              }, [])}
              allowClear
              value={selectedCollaborator}
              onChange={(value) => setSelectedCollaborator(value)}
              placeholder="Selecione uma opção"
              label="Colaborador"
              size="small"
            />
            {!senninhaScreen && (
              <DatePickerCustom
                label={"Data de improdutividade"}
                placeholder={"dd/mm/aaaa"}
                format={"DD/MM/YYYY"}
                styles={{ width: "180px !important" }}
                value={
                  unproductiveDate
                    ? moment(unproductiveDate, "DD-MM-YYYY")
                    : null
                }
                allowClear
                onChange={(date, dateString) => setUnproductiveDate(dateString)}
                width={"180px"}
                suffixIcon={<GingaIcon name="action-refresh" />}
              />
            )}
          </S.LeftUnproductiveSelectFilter>
          <S.LeftUnproductiveButtonFilter>
            <TagCustom
              tags={tags}
              activeTags={activeTags}
              onClick={(tag) => {
                const idx = activeTags.findIndex(
                  (obj) => obj.key === tag.key && obj.value === tag.value
                );
                if (idx === -1) {
                  const updatedActiveTags = [...activeTags, tag];
                  setActiveTags(updatedActiveTags);
                } else {
                  const updatedActiveTags = activeTags.filter(
                    (_, index) => index !== idx
                  );
                  setActiveTags(updatedActiveTags);
                }
              }}
              clickable
            />
          </S.LeftUnproductiveButtonFilter>
        </S.LeftUnproductiveFilter>
        {(selectedCollaborator ||
          unproductiveDate ||
          activeTags.length > 0) && (
          <Button solid onClick={resetFilters}>
            Limpar filtros
          </Button>
        )}
      </S.UnproductiveFilterContainer>
      <Card.Body>
        <Table
          paginationConfig={paginationConfig}
          columns={columns}
          dataSource={dataSource}
          className="ginga-lib-table"
          loading={unproductivityJustificationStatus === "pending"}
          {...(regionalEmails?.includes(userInfos?.email) &&
            !senninhaScreen && {
              rowSelection: {
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRows(selectedRows);
                },
                getCheckboxProps: (item) => ({
                  disabled: !item?.justificativa?.length, // Reutilizamos a lógica de justificativa
                  name: item.justificativa,
                }),
              },
            })}
          locale={{
            triggerDesc: "Ordenar em ordem decrescente",
            triggerAsc: "Ordenar em ordem crescente",
            cancelSort: "Remover a ordenação",
          }}
        />
      </Card.Body>
    </Card.Root>
  );
};

export default UnproductiveTable;
