import styled from "styled-components";


export const AllPageTeam = styled.div`
    padding: 24px 32px;
`;

export const ContainerPageTeam = styled.div`
    display: flex;
    gap: 16px;
`;

export const ContentButtonTeam = styled.div`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => (props.disabled ? "rgba(118, 127, 141, 0.10)" : "#fff")};
    border-radius: 12px;
    display: flex;
    gap: 38px;
    padding: 12px 16px;
    opacity:  ${(props) => (props.disabled ? "40%" : "100%")};
    flex: 1;

    &:hover{
        opacity: ${(props) => (props.disabled ? "40%" : "70%")};
    }
`;

export const LeftContentButtonTeam = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const IconButtonTeam = styled.div`
    background:  rgba(0, 164, 51, 0.10); 
    border-radius: 500px;
    display: flex;
    width: 48px;
    height: 48px;
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
`;

export const TextButtonTeam = styled.div`
    display: flex;
    flex-direction: column;

    span{
        color: #2B323B;
        font-family: "Sharon Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 21.75px */
        letter-spacing: -0.15px;
    }
    p{
        color: rgba(0, 14, 24, 0.59);
        font-family: "Sharon Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 18.85px */
        letter-spacing: -0.13px;
    }
`;

export const RightContentButtonTeam = styled.div``;

export const EditPoloContainer = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 99px;
    background: rgba(118, 127, 141, 0.10);
`;
