import React, { useEffect, useState } from "react";
import { PageLayout } from "../../../Layout/PageLayout";
import { useSelector, useDispatch } from "react-redux";
import { hubsSelector } from "../../../features/senninha/hubsSlice";
import Map from "./Map";
import TopLine from "./TopLine";
import LeftColumn from "./LeftColumn";
import HubCardSelection from "./HubCardSelection";
import useRouter from "../../../hooks/useRouter";
import NewSenninha from "./NewSenninha";

import * as S from "./styles";


export const SenninhaV2 = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const [startWorkflow, setStartWorkflow] = useState(false)

  const { location } = useRouter();

  const dispatch = useDispatch();

  const {
    selectedRegionInfos,
    selectedDistrictInfos,
    selectedHubInfos
  } = useSelector(hubsSelector);

  useEffect(() => {
    if (
      location.state &&
      location?.state?.region === selectedRegionInfos?.children
      && location?.state?.district === selectedDistrictInfos?.children
      && location?.state?.pole === selectedHubInfos?.children
    ) {
      setStartWorkflow(true)
    }
  }, [location])

  return (
    <PageLayout
      titlePage={" |  Senninha"}
      showHamburguerIcon={true}
      setMenuIsOpen={setMenuIsOpen}
    >
      <S.PageAll menuIsOpen={menuIsOpen}>

        {!startWorkflow ? (
          <HubCardSelection
            setStartWorkflow={setStartWorkflow}
          />
        ) : (
          <>
            <TopLine setStartWorkflow={() => setStartWorkflow(false)} />
            <LeftColumn />
            <Map />
            <NewSenninha />
          </>
        )}
      </S.PageAll>
    </PageLayout>
  );
}

export default SenninhaV2
